import './footer.styles.css';
import logo from '../../assets/images/logo.svg';
import logo2 from '../../assets/images/footer2.svg';
import React from 'react';

function Footer() {

    return (
        <footer>
            <div className='container'>
                <div className="footer__inner">
                    <div className="footer__preview">
                        <img src={logo} alt="logo" className="footer__logo" />
                        <p className="footer__title">
                            МІЖНАРОДНИЙ ІНСТИТУТ <br/>
                            ПРОБЛЕМ ВІЛ/СНІДУ ТА ТУБЕРКУЛЬОЗУ
                        </p>
                    </div>
                    <div className="footer__preview">
                        <img src={logo2} alt="logo" className="footer__logo--album" />
                        <p className="footer__title">
                            Надзвичайна ініціатива Президента США з <br/> надання допомоги у боротьбі з ВІЛ/СНІД
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;