import './editableItem.styles.css';
import React from 'react';

function EditableItem(props) {

    const buttonClass = props.isText ? 'item__button--text' : 'item__button'

    const renderControlls = () => {
        return props.controlls.map((controll, index) =>
            <button
                className={buttonClass}
                onClick={props.callbacks[index]}
                key={index}
                title={props.titles?.[index]}
            >
                {props.isText ? controll : <img src={controll} alt="" />}
            </button>
        )
    }

    const bottomPanel = props.controlls.length > 1 ? 'item__controlls--spaced' : 'item__controlls'
    const itemClass = props.highlight ? 'item--highlight': 'item'

    return (
        <div className={itemClass}>
            <span className="item__number">
                {props.number}
            </span>
            <div className="item__content">
                {props.children}
            </div>
            <div className={bottomPanel}>
                {renderControlls()}
            </div>
        </div>
    );
}

export default EditableItem;