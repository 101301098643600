import Result from '../../components/result/result.component'
import SimpleQuestion from '../../components/simpleQuestion/simpleQuestion.component'
import back from '../../assets/images/back.svg'
import forward from '../../assets/images/forward.svg'
import './questionnaireFiller.styles.css'
import { useCallback, useContext, useEffect, useState } from 'react'
import { dateToTransfer, getQuestionareDetails } from '../../services/questionareService'
import { getCurrentPathId } from '../../services/routerService'
import {
    createIndividual,
    createRecord,
    getIndividual,
    getRecordDetails,
    updateRecord,
    createAppeal,
    updateAppeal,
    deleteAppeal
} from '../../services/recordsService'
import { IndividualMessages, INVALID_INDIVIDUAL_DATA, INVALID_PHONE, REQUIRED_MESSAGE, SEVERITY_TYPE } from '../../constants/messages'
import { useNavigate } from 'react-router-dom'
import Loading from '../../components/loading/loading.component'
import { AlertContext } from '../../App'
import { DATE_PATTERN, PHONE_PATTERN } from '../../constants/dataConstants'
import React from 'react'

function QuestionnaireFiller({ editable }) {

    const [currentStep, setCurrentStep] = useState(-1)
    const [questionare, setQuestionare] = useState({
        type: {
            date: ''
        },
        role: '',
        isCompleted: false,
        requestCode: null,
        completedText: '',
        questions: []
    })
    const [answers, setAnswers] = useState([])
    const [individual, setIndividual] = useState({
        pseudonim: '',
        date: '',
        sex: 'male',
        phone: '',
        caseCode: ''
    })
    const [result, setResult] = useState({
        value: []
    })
    const [appeal, setAppeal] = useState({
        id: 0,
        readonly: false,
        phone: '',
        questions: [],
        deleted: true
    })
    const [blocked, setBlocked] = useState(true)
    const [loading, setLoading] = useState(true)
    const [touched, setTouched] = useState(false)
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()

    const showPositive = useCallback(() => {
        setBlocked(true)
        setMessage({message: IndividualMessages.POSITIVE, severity: SEVERITY_TYPE.ERROR})
    },[])

    const loadRecord = useCallback((answers, result) => {
        setAnswers(answers.map(answer => ({
            id: answer.id,
            questionId: answer.questionId,
            answerCaseId: answer.answerId,
            answerText: answer.answerText,
            comment: answer.comment
        })))
        setResult(result)
    }, [setResult, setAnswers, setQuestionare, questionare])

    const showNegative = useCallback(async (response) => {
        const confirmed = window.confirm(IndividualMessages.NEGATIVE)
        if(confirmed) {
            const record = await getRecordDetails(response.result.recordId)
            loadRecord(record.answers, {id: response.result.recordId, value: []})
            setIndividual(response.individual)
            setBlocked(false)
        } else {
            setBlocked(true)
        }
    }, [setBlocked, loadRecord])

    const showExisting = useCallback((response) => {
        setMessage({message: IndividualMessages.EXISTING, severity: SEVERITY_TYPE.WARNING})
        setIndividual(response)
        setBlocked(false)
    },[])

    const actionFactory = {
        400: showPositive,
        405: showNegative,
        406: showExisting,
        200: () => null
    }

    const captilize =  useCallback((string) => {
        const lower = string.toLowerCase()
        return lower.charAt(0).toUpperCase() + lower.slice(1)
    })

    const prepareCasecode = (code) => {
        const splited = code.split('-');
        console.log(splited,code)

        splited[0] = captilize(splited[0]);
        splited[1] = captilize(splited[1]);
        splited[2] = captilize(splited[2]);

        return splited.join('-')
    }

    const fetchRecord = async (id) => {
        setBlocked(false)
        const response = await getRecordDetails(id)

        let data = { type: 'manually' }

        if(!response.record.manualy) {
            data = await getQuestionareDetails(response.record.typeId)
        }
        
        loadRecord(response.answers, {id, value: response.results.map(result => result.id)})
        setIndividual({
            id: response.record.individualId,
            date: response.record.individualDate,
            pseudonim: response.record.pseudonim,
            phone: response.record.phone,
            caseCode: response.record.caseCode?.replace(/_/g,'-'),
            sex: response.record.sex
        })

        setQuestionare({
            ...data,
            isCompleted: response.record.isCompleted,
            completedText: response.record.completedText,
            requestCode: response.record.requestCode,
            role: response.record.role
        })

        setAppeal({
            ...(response.appeal || {}),
            phone: response.record.phone,
            questions: response.appeal?.questions || [],
            deleted: !response.appeal
        })
        
        setCurrentStep(response.answers.length)
    }
 
    useEffect(() => {
        const id = getCurrentPathId()
        setLoading(true)
        new Promise(res => res())
            .then(() => {
                if(editable) {
                    return fetchRecord(id)
                } else {
                    setIndividual({
                        pseudonim: '',
                        date: '',
                        sex: 'male',
                        phone: ''
                    })
                 
                    return getQuestionareDetails(id)
                        .then(data => setQuestionare(data))
                }
            })
            .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
            .finally(() => setLoading(false))
    }, [])
    
    const changeIndividual = (field, value) => {
        setIndividual({
            ...individual,
            [field]: value,
            id: undefined
        })
        setBlocked(false)
        setTouched(true)
    }

    const changeAnswers = (id, answerId, answerText, comment) => {
        const exist = answers.some(answer => answer.questionId === id)
        let newAnswers
        if(exist) {
            newAnswers = answers.map(answer => {
                if(answer.questionId === id) {
                    return {
                        ...answer,
                        questionId: id,
                        answerCaseId: answerId,
                        answerText,
                        comment
                    }
                }
                return answer
            })
        } else {
            newAnswers = [...answers, {questionId: id, answerCaseId: answerId, answerText, comment}]
        }
        setAnswers(newAnswers)
        setTouched(true)
    }

    const checkIndividualHandler = async () => {
        if(!individual.id && touched) {
            setLoading(true)
            const id = getCurrentPathId()
            try {
                const response = await getIndividual(individual.pseudonim, id)
                if(!response) {
                    return
                }
                actionFactory[response.status](response.data)
                setTouched(false)
            } catch (e) {
                setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
            } finally {
                setLoading(false)
            }
        }
    }

    const changeCompleted = (completed) => {
        setQuestionare({
            ...questionare,
            isCompleted: completed.isCompleted,
            completedText: completed.completedText
        })
        setTouched(true)
    }

    const completedState = {
        isCompleted: questionare.isCompleted,
        completedText:  questionare.completedText
    }

    const changeAppeal = (appeal) => {
        setAppeal(appeal)
        setTouched(true)
    }

    const changeCaseCode = (code) => {
        setIndividual({
            ...individual,
            caseCode: code
        }) 
        setTouched(true)
    }

    const changeRequestCode = (code) => {
        setQuestionare({
            ...questionare,
            requestCode: code
        }) 
        setTouched(true)
    }

    const changeResult = (value) => {
        setResult({
            ...result,
            value
        })
        setTouched(true)
    }

    const cancelHandler = () => {
        navigate('./..')
    }
    
    const getAnswer = (index) => {
        return answers
            .find(answer => answer.questionId === questionare.questions[index]?.questionId)
    }

    const checkAnswers = () => {
        let filled = true
        for(let i = 0; i < questionare.questions.length; i++) {
            if(questionare.questions[i].answerType === 2) {
                filled &= !!getAnswer(i)?.comment
            } else {
                filled &= !!getAnswer(i)?.answerCaseId
            }
        }
        return filled
    }

    const checkIndividual = () => {
        return individual.date.match(DATE_PATTERN)
            && +individual.date < new Date().getFullYear()
            && individual.pseudonim
    }

    const checkFilledIndividual = () => {
        return individual.date
            && individual.pseudonim
    }

    const nextFromIndividualHandler = () => {
        if(!checkFilledIndividual()) {
            setMessage({severity: SEVERITY_TYPE.ERROR, message: REQUIRED_MESSAGE})
            return
        }
        if(!checkIndividual()) {
            setMessage({severity: SEVERITY_TYPE.ERROR, message:INVALID_INDIVIDUAL_DATA})
            return
        }
        setCurrentStep(currentStep + 1)
    }
    

    const addHandler = async () => {
        if(!touched) {
            cancelHandler()
            return
        }

        if(appeal.readonly) {
            cancelHandler()
            return
        }

        if(questionare.questions && (!checkAnswers() || ! checkIndividual())) {
            setMessage({message: 'Введіть усі відповіді', severity: SEVERITY_TYPE.ERROR})
            return
        }

        if(!appeal.deleted && !PHONE_PATTERN.test(appeal.phone)) {
            setMessage({ message: INVALID_PHONE, severity: SEVERITY_TYPE.ERROR })
            return
        }
        
        setLoading(true)
        try {
            if(individual.id && !result.id) {
                await createRecord({
                    answers,
                    date: dateToTransfer(new Date()),
                    individual: individual.id,
                    type: questionare.type.id,
                    phone: appeal.phone
                })
            } else if(individual.id) {
                await updateRecord(result.id, {
                    answers,
                    result: result.value,
                    date: dateToTransfer(new Date()),
                    individual: individual.id,
                    isCompleted: questionare.isCompleted,
                    completedText: questionare.completedText,
                    requestCode: questionare.requestCode !== -1 ? questionare.requestCode : undefined,
                    phone: appeal.phone,
                    caseCode: prepareCasecode(individual.caseCode).replace(/-/g, '_')
                })
            } else {
                const response = await createIndividual(individual)
                await createRecord({
                    answers,
                    date: dateToTransfer(new Date()),
                    individual: response.id,
                    type: questionare.type.id
                })
            }

            if (!result.id) {
                navigate('./..')
                return
            }

            if (appeal.deleted && appeal.id) {
                await deleteAppeal(appeal.id)
            }

            if (!appeal.id && !appeal.deleted) {
                await createAppeal(individual.id, result.id, appeal.questions)
            }

            if (!appeal.deleted && appeal.id) {
                await updateAppeal(appeal.id, individual.id, result.id, appeal.questions)
            }

            navigate('./..')
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(false)
        }
    }

    const valueCases = {
        1: getAnswer(currentStep)?.answerCaseId,
        2: getAnswer(currentStep)?.comment
    }

    const renderManualRecord = () => {
        return  <section className='filler'>
            <div className="container">
                <h1 className='filler__title'>Швидке заповнення анкети</h1>
                <Result
                    answers={answers}
                    questions={questionare.questions}
                    onChange={changeResult}
                    onAppeal={changeAppeal}
                    appeal={appeal}
                    value={result.value}
                    role={'doctor'}
                    onCompleted={changeCompleted}
                    completed={completedState}
                    requestCode={questionare.requestCode}
                    onRequestCode={changeRequestCode}
                    onCode={changeCaseCode}
                    code={individual.caseCode}
                />
                <div className="filler__controlls">
                    <button
                        className='filler__button button'
                        onClick={cancelHandler}
                    >
                        Скасувати
                    </button>
                    <button
                        className='filler__button button'
                        onClick={addHandler}
                    >
                        Готово
                    </button>
                </div>
            </div> 
        </section>
    }

    const renderContent = () => {
        if(questionare.type === 'manually') {
            return renderManualRecord()
        }

        if(!questionare.type.id) {
            return
        }

        const passedValue = valueCases[questionare.questions[currentStep]?.answerType]
        const lastStep = editable ? questionare.questions.length : questionare.questions.length - 1

        return  <section className='filler'>
            <div className="container">
                <h1 className='filler__title'>Заповнення анкети №{questionare.type.number}</h1>
                <p className='filler__description'>
                    {questionare.type.name}
                </p>
                {
                    currentStep === -1 ?
                        <div className="filler__content">
                            <p className="filler__hint">Введіть дані:</p>
                            <div className="filler__individual">
                                <label className="filler__label">
                                    Псевдонім
                                    <input
                                        type="text"
                                        className="filler__input"
                                        value={individual.pseudonim}
                                        onChange={(e) => changeIndividual('pseudonim', e.target.value)}
                                        onBlur={checkIndividualHandler}
                                        readOnly={editable}
                                    />
                                </label>
                                <label className="filler__label">
                                    Рік народження
                                    <input
                                        type="number"
                                        className="filler__input"
                                        value={individual.date}
                                        readOnly={!!individual.id || editable || blocked}
                                        onChange={(e) => changeIndividual('date', e.target.value)}
                                    />
                                </label>
                                <label className="filler__label">
                                    Стать
                                    <select
                                        className="filler__input"
                                        disabled={!!individual.id || editable || blocked}
                                        value={individual.sex}
                                        onChange={(e) => changeIndividual('sex', e.target.value)}
                                    >
                                        <option value="male">Чоловік</option>
                                        <option value="female">Жінка</option>
                                    </select>
                                </label>
                            </div>
                        </div>:
                        (currentStep <= lastStep && !editable) || (currentStep < lastStep && editable)?
                            <SimpleQuestion
                                question={questionare.questions[currentStep]}
                                onChange={changeAnswers}
                                value={passedValue}
                            />:
                            editable && currentStep === lastStep ?
                                <Result
                                    answers={answers}
                                    questions={questionare.questions}
                                    onChange={changeResult}
                                    onAppeal={changeAppeal}
                                    appeal={appeal}
                                    value={result.value}
                                    role={questionare.role}
                                    onCompleted={changeCompleted}
                                    completed={completedState}
                                    requestCode={questionare.requestCode}
                                    onRequestCode={changeRequestCode}
                                    onCode={changeCaseCode}
                                    code={individual.caseCode}
                                />:
                                null
                }
                <div className="filler__controlls">
                    {
                        currentStep !== -1 ?
                            <button
                                className='filler__button--small button'
                                onClick={()=>setCurrentStep(currentStep - 1)}
                            >
                                <img src={back} alt="" />
                            </button>:
                            null
                    }
                    <button
                        className='filler__button button'
                        onClick={cancelHandler}
                    >
                        Скасувати
                    </button>
                    {currentStep === lastStep ?
                        <button
                            className='filler__button button'
                            onClick={addHandler}
                        >
                            Готово
                        </button>:
                        currentStep === -1 ?
                            <button
                                className='filler__button button'
                                disabled={blocked}
                                onClick={nextFromIndividualHandler}
                            >
                                Далі
                            </button>:
                            <button
                                className='filler__button--small button'
                                onClick={()=>setCurrentStep(currentStep + 1)}
                            >
                                <img src={forward} alt="" />
                            </button>
                    }
                </div>
            </div>
        </section>
    }
       
    return loading ? <Loading /> : renderContent();
}

export default QuestionnaireFiller;
