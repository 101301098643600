import { AdminRoutes, AssistentRoutes, AuthRoutes, ProviderRoutes, PsychologerRoutes, UserRoutes } from './pages';
import user from '../assets/images/user.svg';

export const adminNavigation = [
    {
        type: 'index',
        path: AdminRoutes.HOME
    },
    {
        type: 'link',
        label: 'Статистика',
        path: AdminRoutes.STATISITC
    },
    {
        type: 'link',
        label: 'Користувачі',
        path: AdminRoutes.USERS
    },
    {
        type: 'link',
        label: 'Анкети',
        path: AdminRoutes.QUESTIONNAIRES
    },
    {
        type: 'link',
        label: 'Записи',
        path: UserRoutes.RECORDS
    },
    {
        type: 'link',
        label: 'Місця тестування',
        path: AdminRoutes.TEST_PLACES
    },
    {
        type: 'link',
        label: 'Постачання тестів',
        path: AdminRoutes.TEST_SUPPLIES
    },
    {
        type: 'link',
        label: 'Швидкий запис',
        path:   AdminRoutes.MANUAL_RECORD
    },
    {
        type: 'icon',
        src: user,
        path: AdminRoutes.PROFILE
    }
]

export const userNavigation = [
    {
        type: 'index',
        path: UserRoutes.HOME
    },
    {
        type: 'link',
        label: 'Статистика',
        path: UserRoutes.STATISITC
    },
    {
        type: 'link',
        label: 'Заповнити анкету',
        path: UserRoutes.QUESTIONNAIRES
    },
    {
        type: 'link',
        label: 'Анкети',
        path: UserRoutes.RECORDS
    },
    {
        type: 'icon',
        src: user,
        path: UserRoutes.PROFILE
    }
]

export const psychologerNavigation = [
    {
        type: 'index',
        path: PsychologerRoutes.HOME
    },
    {
        type: 'link',
        label: 'Статистика',
        path: PsychologerRoutes.STATISITC
    },
    {
        type: 'link',
        label: 'Заповнити анкету',
        path: PsychologerRoutes.QUESTIONNAIRES
    },
    {
        type: 'link',
        label: 'Анкети',
        path: PsychologerRoutes.RECORDS
    },
    {
        type: 'link',
        label: 'Звернення',
        path: PsychologerRoutes.APPEALS
    },
    {
        type: 'link',
        label: 'Сеанси',
        path: PsychologerRoutes.SESSIONS
    },
    {
        type: 'icon',
        src: user,
        path: PsychologerRoutes.PROFILE
    }
]

export const assistentNavigation = [
    {
        type: 'index',
        path: AssistentRoutes.HOME
    },
    {
        type: 'link',
        label: 'Додати запис',
        path: AssistentRoutes.MANUAL_RECORD
    },
    {
        type: 'link',
        label: 'Записи',
        path: AssistentRoutes.RECORDS
    },
    {
        type: 'icon',
        src: user,
        path: AssistentRoutes.PROFILE
    }
]

export const providerNavigation = [
    {
        type: 'index',
        path: PsychologerRoutes.HOME
    },
    {
        type: 'link',
        label: 'Постачання тестів',
        path: ProviderRoutes.SUPPLIES
    },
    {
        type: 'icon',
        src: user,
        path: PsychologerRoutes.PROFILE
    }
]

export const authNavigation = [
    {
        type: 'index',
        path: AuthRoutes.LOGIN
    }
]
