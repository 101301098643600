import { HOST } from '../environment'

export const getDataAbountMe = async () => {
    const data = await fetch(`${HOST}/users/me`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const getUsers = async (limit, offset) => {
    const data = await fetch(`${HOST}/users?limit=${limit}&offset=${offset}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const getPsychologers= async () => {
    const data = await fetch(`${HOST}/users/psychologers`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}


export const getUserDetails = async (id) => {
    const data = await fetch(`${HOST}/users/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const updateUserDetails = async (id, userData) => {
    const data = await fetch(`${HOST}/users/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(userData)
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const updateUserStatus = async (userId, blocked) => {
    const data = await fetch(`${HOST}/users/${userId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({blocked})
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const deleteUser = async (id) => {
    const data = await fetch(`${HOST}/users/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}