import { useContext, useState } from 'react';
import { AlertContext } from '../../App';
import { SEVERITY_TYPE } from '../../constants/messages';
import { sendLoginData } from '../../services/authService';
import Loading from '../loading/loading.component';
import './auth.styles.css';
import React from 'react';

function Auth({callback}) {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [, setMessage] = useContext(AlertContext)

    const loginHandler = () => {
        if(!login || !password) {
            setMessage({ message: 'Введіть дані', severity: SEVERITY_TYPE.ERROR })
            return
        }
        setLoading(true)
        sendLoginData(login, password)
            .then((data) => {
                callback({
                    id: data.id,
                    role: data.type,
                    cityId: data.cityId,
                    cityName: data.cityName
                })
            })
            .catch(e => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
            .finally(() => setLoading(false))
    }

    return (
        <section className='auth'>
            <div className='container'>
                <form className="auth__form">
                    <h1  className="auth__title">Авторизація</h1>
                    <div className="auth__controlls">
                        <label className="auth__label" >
                            Логін
                            <input
                                type="text"
                                className="auth__input"
                                value={login}
                                onChange={(e) => setLogin(e.target.value)}
                            />
                        </label>
                        <label className="auth__label" >
                            Пароль
                            <input
                                type="password"
                                className="auth__input"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                        {loading ? <Loading /> : null}
                    </div>
                    <button
                        className="auth__button button"
                        onClick={loginHandler}
                        type="button"
                        disabled={loading}
                    >
                        Увійти
                    </button>
                </form>
            </div>
        </section>
    );
}

export default Auth;