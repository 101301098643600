import { HOST } from '../environment';

export const getPlaces = async (page, size) => {
    const data = await fetch(`${HOST}/testing/places?page=${page}&size=${size}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const getSupplies = async (page, size, status) => {
    let url = `${HOST}/testing/supplies?page=${page}&size=${size}`;

    if(status) {
        url += `&status=${status}`
    }

    const data = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const getPlaceById = async (id) => {
    const data = await fetch(`${HOST}/testing/places/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    console.log(data)
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const getAllPlaces = async () => {
    const data = await fetch(`${HOST}/testing/places/all`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const getSupplyById = async (id) => {
    const data = await fetch(`${HOST}/testing/supplies/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const deletePlace = async (placeId) => {
    const data = await fetch(`${HOST}/testing/places/${placeId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const deleteSupply = async (supplyId) => {
    const data = await fetch(`${HOST}/testing/supplies/${supplyId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const createPlace = async (place) => {
    const data = await fetch(`${HOST}/testing/places`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(place),
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const createSupply = async (supply) => {
    const data = await fetch(`${HOST}/testing/supplies`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(supply),
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const updatePlace = async (placeId, place) => {
    const data = await fetch(`${HOST}/testing/places/${placeId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(place),
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const updateSupply = async (supplyId, supply) => {
    const data = await fetch(`${HOST}/testing/supplies/${supplyId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(supply),
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}