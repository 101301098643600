import { HOST } from '../environment'

export async function getQuestionares(limit, offset, onlyRelevant) {
    return fetch(`${HOST}/questionareTypes?limit=${limit}&offset=${offset}&onlyRelevant=${onlyRelevant}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function updateQuestionare(id, data) {
    return fetch(`${HOST}/questionareTypes/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function addQuestionare(data) {
    return fetch(`${HOST}/questionareTypes`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}


export async function deleteQuestionare(id) {
    return fetch(`${HOST}/questionareTypes/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function getQuestionareDetails(id) {
    return fetch(`${HOST}/questionareTypes/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function getAnswerTypes() {
    return fetch(`${HOST}/questionareTypes/answerTypes`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export function JSONdateToFormat(date, withTime = false) {
    if(!date) {
        return ''
    }
    const parsed = new Date(date)
    const {day, month, year, hours, minutes} = dateToParts(parsed)

    let string = `${day}.${month}.${year}`

    if(withTime) {
        string += ` ${hours}:${minutes}`
    }
    return string
}

export function dateToTransfer(date) {
    if(!date) {
        return ''
    }
    const {day, month, year} = dateToParts(date)
    return `${year}-${month}-${day}`
}

export function JSONdateToInput(date) {
    if(!date) {
        return ''
    }
    const parsed = new Date(date)
    const {day, month, year} = dateToParts(parsed)
    return `${year}-${month}-${day}`
}

function dateToParts(date) {
    
    return {
        year: new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date),
        month: new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date),
        day: new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date),
        hours: date.getHours() > 10 ? date.getHours() : `0${date.getHours()}`,
        minutes: date.getMinutes() > 10 ? date.getMinutes() : `0${date.getMinutes()}`
    }
}