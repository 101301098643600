export const ITEMS_PER_PAGE = 10;

export const PROFILE_TYPES = {
    VIEW: 'view',
    EDIT: 'edit',
    CREATOR: 'creator'
}

export const APPEAL_TYPES = {
    EDIT: 'edit',
    CREATOR: 'creator'
}

export const QUESTIONARES_TYPES = {
    SHORT: 'short',
    EDIT: 'edit',
}

export const TEST_PLACE = {
    EDIT: 'edit',
}

export const TEST_SUPPLY = {
    EDIT: 'edit',
    APPROVABLE: 'approvable',
    SUPPLIABLE: 'suppliable'
}

export const STATUS_FACTORY = {
    'to_psycho': 'Направлений до психолога',
    'in_process': 'Ведуться консультації',
    'to_center': 'Направлений до центру реабілітації',
    'given': 'Опрацьований',
}

export const SUPPLY_STATUSES = {
    'created': {
        text: 'Створено',
        editOnly: true
    },
    'approved':  {
        text: 'Підтверджено',
        editOnly: false
    },
    'supplied': {
        text: 'Доставлено',
        editOnly: false
    }
}


export const PHONE_PATTERN = /^\+380[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
export const DATE_PATTERN = /^[0-9]{4}$/

export const DURATION_MASK = [/[0-9]/, 'г', 'о', 'д', '.', ' ', /[0-5]/, /[0-9]/, 'х', 'в', '.']
export const PHONE_MASK = ['+', '3', '8', '0', /[0-9]/, /[0-9]/,/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
export const CODE_MASK = [
    /[А-Я, І, Є, A-Z]/i,
    /[А-Я, І, Є, A-Z]/i,
    /[А-Я, І, Є, A-Z]/i,
    '-',
    /[А-Я, І, Є, A-Z]/i,
    /[А-Я, І, Є, A-Z]/i,
    '-',
    /[А-Я, І, Є, A-Z]/i,
    /[А-Я, І, Є, A-Z]/i,
    '-',
    /[0-3]/,
    /[0-9]/,
    '-',
    /[0-1]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    '-',
    /[ч,ж]/i
]

export const TIME_MASK = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]

export const LENGTH_MASK = (count) => {
    const mask = [];

    for(let i = 0; i < count; i++) {
        mask.push(/[А-Я, І, Є, A-Z]/i)
    }

    return mask
}
export const CODE_HINT = `
Формується за правилом: ХХХ_УУ_ZZ_DD_MM_YY_s, де:
ХХХ – перші три символи прізвища;
УУ – перші дві літери імені;
ZZ – перші дві літери по батькові; DD – дата народження;
MM – місяць народження;
YY – рік народження;
s – стать (ч – чоловіча; ж – жіноча)
`

export const STATUS_SEPARATOR = ', '
export const NEGATIVE_ID = 1
