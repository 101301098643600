import { useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import { getCurrentPathId } from '../../services/routerService';
import { INVALID_PLACE_DATA, NO_SUPPLY_MESSAGE, SEVERITY_TYPE, SUCCESS_PLACE_UPDATED_MESSAGE } from '../../constants/messages';
import './supplyFiller.styles.css';
import { AlertContext } from '../../App';
import Loading from '../../components/loading/loading.component';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { createSupply, getAllPlaces, getSupplyById, updateSupply } from '../../services/testService';
import { TEST_SUPPLY } from '../../constants/dataConstants';

function SupplyFiller({ type }) {
    const [supply, setSupply] = useState()
    const [places, setPlaces] = useState()
    let initialStatus = ''
    const [loading, setLoading] = useState(true)
    const [touched, setTouched] = useState(false)
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()
    
    const changeAddress = event => {
        setTouched(true)
        setSupply({
            ...supply,
            address: event.target.value
        })
    }

    const changeHIVCount = event => {
        setTouched(true)
        setSupply({
            ...supply,
            HIVCount: +event.target.value
        })
    }

    const changeMultiCount = event => {
        setTouched(true)
        setSupply({
            ...supply,
            multiCount: +event.target.value
        })
    }

    const changeReciever = event => {
        setTouched(true)
        setSupply({
            ...supply,
            receiver: event.target.value
        })
    }

    const changePostAddress = event => {
        setTouched(true)
        setSupply({
            ...supply,
            postAddress: event.target.value
        })
    }

    const changeStatus = status => {
        setTouched(true)

        if (status === supply.status) {
            setSupply({
                ...supply,
                status: initialStatus
            })
        } else {
            initialStatus = supply.status
            setSupply({
                ...supply,
                status
            })
        }
        
    }

    const changePlace = event => {
        setTouched(true)
        setSupply({
            ...supply,
            place: +event.target.value
        })
    }

    const addHandler = async () => {
        try {
            setLoading(true)
            await createSupply(
                {   
                    HIVCount: supply.HIVCount,
                    testingPlace: supply.place,
                    multiCount: supply.multiCount,
                    postAddress: supply.postAddress,
                    address: supply.address,
                    receiver: supply.receiver,
                    status: supply.status,
                }
            )
    
            navigate('./../')
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(false)
        }
    }

    const updateHandler = async () => {
        try {
            setLoading(true)

            await updateSupply(
                supply.id,
                {   
                    HIVCount: type === TEST_SUPPLY.SUPPLIABLE ? undefined : supply.HIVCount,
                    testingPlace: type === TEST_SUPPLY.SUPPLIABLE ? undefined : supply.place,
                    multiCount: type === TEST_SUPPLY.SUPPLIABLE ? undefined : supply.multiCount,
                    postAddress: type === TEST_SUPPLY.SUPPLIABLE ? undefined : supply.postAddress,
                    address: type === TEST_SUPPLY.SUPPLIABLE ? undefined : supply.address,
                    status: supply.status,
                    receiver: type === TEST_SUPPLY.SUPPLIABLE ? undefined : supply.receiver,
                }
            )
            setTouched(false)
            setMessage({message: SUCCESS_PLACE_UPDATED_MESSAGE, severity: SEVERITY_TYPE.SUCCESS})
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(false)
        }
    }

    const calncelHandler = () => {
        navigate('./../')
    }

    const dataFetcher = useCallback(() => {
        const id = getCurrentPathId()

        setLoading(true)
        const placesPromise = getAllPlaces()

        if (id === 'create' && type !== TEST_SUPPLY.SUPPLIABLE) {
            placesPromise
                .then(res =>  {
                    setPlaces(res)
                    setSupply({
                        HIVCount: 0,
                        place: res[0].id,
                        multiCount: 0,
                        postAddress: '',
                        address: '',
                        status: '',
                        receiver: '',
                    })
                })
        } else if(type && id !== 'create') {
            placesPromise
                .then(res => {
                    setPlaces(res)
                    return getSupplyById(id)
                })
                .then(res => setSupply({
                    id: res.id,
                    HIVCount: res.HIVCount,
                    place: res.testingPlace,
                    multiCount:  res.multiCount,
                    postAddress:  res.postAddress,
                    address: res.address,
                    status: res.status,
                    receiver: res.receiver
                }))
        } else {
            setMessage({message: NO_SUPPLY_MESSAGE, severity: SEVERITY_TYPE.ERROR})
        }
       
        placesPromise
            .catch((e) => {
                setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})}
            )
            .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        dataFetcher()
    }, [])

    const renderPlaces = () =>
        places.map(place =>
            <option value={place.id} key={place.id}>
                {place.cityName}/{place.address}
            </option>)

    const renderPlaceSelector = () => {
        if (type != TEST_SUPPLY.APPROVABLE) return null;

        return <li className="profile__field">
            <h3 className="profile__name">Місце тестування:</h3>
            <select
                disabled={type === TEST_SUPPLY.SUPPLIABLE || supply.status === 'supplied'}
                className="profile__value"
                value={supply.place}
                onChange={changePlace}
            >
                {renderPlaces()}
            </select>
        </li>
    }

    const renderApprovePicker = () =>
        supply.status !== 'supplied' ?
            <div className="supply-checkbox simple__field">
                <label className="simple__check">
                    <input type="checkbox"
                        hidden
                        disabled={supply.status === 'supplied'}
                        onChange={()=>changeStatus('approved')}
                        checked={supply.status === 'approved'}
                    />
                    <span className="simple__frame" />
                </label>
                <span className="label">Підтвердити запит</span>
            </div> :
            <p className='hint'>Увага! За цим запитом тести вже доставлено</p>
    

    const renderSupplyPicker = () => 
        <div className="supply-checkbox simple__field">
            <label className="simple__check">
                <input type="checkbox"
                    hidden
                    onChange={()=>changeStatus('supplied')}
                    checked={supply.status === 'supplied'}
                />
                <span className="simple__frame" />
            </label>
            <span className="label">Підтвердити постачання</span>
        </div>
        

    const pickFactory = {
        [TEST_SUPPLY.APPROVABLE]: renderApprovePicker,
        [TEST_SUPPLY.SUPPLIABLE]: renderSupplyPicker
    }

    const renderStatusPicker = () => {
        const renderer = pickFactory[type]
        if(!renderer) return ''
        return pickFactory[type]()
    }

    const validateData = () => {
        const filled = !!supply.HIVCount
            && !!supply.multiCount

        return filled
    }

    const onSubmit = () => {
        if(!validateData()) {
            setMessage({message: INVALID_PLACE_DATA, severity: SEVERITY_TYPE.ERROR})
            return
        }
    
        const id = getCurrentPathId()
        if(id === 'create') {
            addHandler()
        } else {
            updateHandler()
        }
    }

   

    const renderContent = () => {
        if(!supply) {
            return
        }

        const placeData = places?.find(place => place.id === supply.place);

        const disabled = type === TEST_SUPPLY.SUPPLIABLE || supply.status === 'supplied'

        return <section className='place-filler'>
            <div className='container'>
                <div className='appeal__inner'>
                    <h2 className="profile__title">Запит постачання тестів</h2>
                    <ul className="profile__content">
                        {renderPlaceSelector()}
                        <li className="profile__field">
                            <h3 className="profile__name">Адреса:</h3>
                            <input
                                disabled={disabled}
                                className="appeal__value"
                                value={supply.address || placeData.address}
                                onChange={changeAddress}
                            ></input>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Отримувач:</h3>
                            <input
                                disabled={disabled}
                                className="appeal__value"
                                value={supply.receiver || placeData.reciever}
                                onChange={changeReciever}
                            >
                            </input>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Адреса пошти:</h3>
                            <input
                                disabled={disabled}
                                className="appeal__value"
                                value={supply.postAddress || placeData.postAddress}
                                onChange={changePostAddress}
                            >
                            </input>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Кількість ВІЛ:</h3>
                            <input
                                disabled={disabled}
                                className="appeal__value"
                                value={supply.HIVCount}
                                onChange={changeHIVCount}
                                type='number'
                            >
                            </input>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Кількість мулти-тестів:</h3>
                            <input
                                disabled={disabled}
                                className="appeal__value"
                                value={supply.multiCount}
                                onChange={changeMultiCount}
                                type='number'
                            >
                            </input>
                        </li>
                        {renderStatusPicker()}
                    </ul>
                </div>

                <div className="profile__controlls">
                    <button
                        className="profile__button button"
                        onClick={calncelHandler}
                    >
                        {touched ? 'Скасувати' : 'Назад'}
                    </button>
                    <button
                        className="profile__button button"
                        onClick={onSubmit}
                        disabled={!touched}
                    >
                        Зберегти
                    </button>
                
                </div>

            </div>
        </section>
    }

    return loading ? <Loading /> : renderContent()
}

export default SupplyFiller;