import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PHONE_MASK, PHONE_PATTERN, PROFILE_TYPES } from '../../constants/dataConstants';
import { registerUser } from '../../services/authService';
import { getCities } from '../../services/recordsService';
import { getCurrentPathId } from '../../services/routerService';
import Loading from '../../components/loading/loading.component'
import { getDataAbountMe, getUserDetails, updateUserDetails } from '../../services/usersService';
import './profile.styles.css';
import { AlertContext } from '../../App';
import { PHONE_MESSAGE, REQUIRED_MESSAGE, SEVERITY_TYPE } from '../../constants/messages';
import MaskedInput from 'react-text-mask';
import React from 'react';
import { getAllPlaces } from '../../services/testService';

const initialDetails = {
    login: '',
    cridentials: '',
    phone: '',
    type: 'doctor',
    cityId: -1,
    password: '',
    postDepartment: ''
}

function Profile({callback, type}) {

    const [details, setDetails] = useState(initialDetails)
    const [cities, setCities] = useState([])
    const [places, setPlaces] = useState([])
    const [loading, setLoading] = useState(true)
    const [touched, setTouched] = useState(false)
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()

    const changeDetails = (field, data) => {
        setTouched(true)
        console.log(field, data)
        setDetails({
            ...details,
            [field]: data
        })
    }

    const checkCreatedUser = () => {
        return details.cityId !== -1
            && details.cridentials
            && details.password
            && details.phone
            && details.login
    }

    const checkEditedUser = () => {
        return details.cityId !== -1
            && details.cridentials
            && details.phone
            && details.login
    }

    const renderCities = () => {
        return cities.map(city =>
            <option value={city.id} key={city.id}>
                {city.name}
            </option>
        )
    }

    const renderPlaces = () => {
        return places.map(place =>
            <option value={place.id} key={place.id}>
                {place.cityName}/{place.address}
            </option>
        )
    }

    const cancelHandler = () => {
        navigate('./..')
    }

    const updateHandler = async () => {
        if(!checkEditedUser()) {
            setMessage({message: REQUIRED_MESSAGE, severity: SEVERITY_TYPE.ERROR})
            return
        }
        if(!details.phone.match(PHONE_PATTERN)) {
            setMessage({message: PHONE_MESSAGE, severity: SEVERITY_TYPE.ERROR})
            return
        }
        setLoading(true)
        const id = getCurrentPathId()

        try {
            await updateUserDetails(id, {
                login: details.login,
                phone: details.phone,
                cridentials: details.cridentials,
                city: details.cityId,
                type: details.type,
                password: details.password,
                postDepartment: details.postDepartment,
                testingPlace: +details.place
            })
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(false)
        }
        setTouched(false)
    }

    const addHandler = async () => {
        if(!checkCreatedUser()) {
            setMessage({message: REQUIRED_MESSAGE, severity: SEVERITY_TYPE.ERROR})
            return
        }
        if(!details.phone.match(PHONE_PATTERN)) {
            setMessage({message: PHONE_MESSAGE, severity: SEVERITY_TYPE.ERROR})
            return
        }
        const filled = Object.values(details).every(value => !!value)
        setLoading(true)
        if(!filled) {
            alert('Заповніть поля')
            return
        }
        try {
            await registerUser({
                login: details.login,
                phone: details.phone,
                cridentials: details.cridentials,
                city: details.cityId,
                type: details.type,
                password: details.password,
                postDepartment: details.postDepartment,
                testingPlace: +details.place
            })
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(false)
        }
        setTouched(false)
    }

    const loadUserBuId = async () => {
        const id = getCurrentPathId()
        const data = await getUserDetails(id)
        setDetails({
            ...data,
            place: data.testingPlace
        })
        const cities = await getCities()
        setCities(cities)
        const  places = await getAllPlaces()
        setPlaces(places)
    }

    const loadMe = async () => {
        const data = await getDataAbountMe()
        setDetails(data)
    }

    const loadCities = async () => {
        const  cities = await getCities()
        setCities(cities)
        const  places = await getAllPlaces()
        setPlaces(places)
        setDetails({
            ...initialDetails,
            cityId: cities[0].id,
            place: places[0].id
        })
    }

    const loadFactory = {
        [PROFILE_TYPES.EDIT]: loadUserBuId,
        [PROFILE_TYPES.VIEW]: loadMe,
        [PROFILE_TYPES.CREATOR]: loadCities
    }

    useEffect(() => {
        setLoading(true)
        loadFactory[type]()
            .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
            .finally(() => setLoading(false))
    }, [type])

    const renderContent = () =>
        <section className='profile'>
            <div className='container'>
                <div className='profile__inner'>
                    <h2 className="profile__title">Профіль</h2>
                    <ul className="profile__content">
                        <li className="profile__field">
                            <h3 className="profile__name">Логін:</h3>
                            <input className="profile__value"
                                placeholder="Веведіть ім'я"
                                readOnly={type===PROFILE_TYPES.VIEW}
                                value={details.login}
                                onChange={(e) => changeDetails('login', e.target.value)}
                            />
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">ПІБ:</h3>
                            <input
                                placeholder="Веведіть ПІБ"
                                className="profile__value"
                                readOnly={type===PROFILE_TYPES.VIEW}
                                value={details.cridentials}
                                onChange={(e) => changeDetails('cridentials', e.target.value)}
                            />
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Номер телефону:</h3>
                            <MaskedInput
                                mask={PHONE_MASK}
                                placeholder="Веведіть номер"
                                className="profile__value"
                                readOnly={type===PROFILE_TYPES.VIEW}
                                value={details.phone}
                                onChange={(e) => changeDetails('phone', e.target.value)}
                            />
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Місто:</h3>
                            {
                                type===PROFILE_TYPES.VIEW ?
                                    <input
                                        className="profile__value"
                                        readOnly
                                        defaultValue={details.city}
                                    />:
                                    <select
                                        className="profile__value"
                                        value={details.cityId}
                                        onChange={(e) => changeDetails('cityId', e.target.value)}>
                                        {renderCities()}
                                    </select>
                            }
                        </li>
                        { type!==PROFILE_TYPES.VIEW || details.placeCity ? 
                            <li className="profile__field">
                                <h3 className="profile__name">Місце тестування:</h3>
                                {
                                    type===PROFILE_TYPES.VIEW ?
                                        <input
                                            className="profile__value"
                                            readOnly
                                            defaultValue={details.placeCity + '/' + details.placeAddress}
                                        />:
                                        <select
                                            className="profile__value"
                                            value={details.place}
                                            onChange={(e) => changeDetails('place', e.target.value)}>
                                            {renderPlaces()}
                                        </select>
                                }
                            </li> : null
                        }
                        {
                            type !== PROFILE_TYPES.VIEW || details.postDepartment ?
                                <li className="profile__field">
                                    <h3 className="profile__name">Відділення Нової пошти:</h3>
                                    <input className="profile__value"
                                        placeholder="Веведіть відділення"
                                        readOnly={type===PROFILE_TYPES.VIEW}
                                        value={details.postDepartment}
                                        onChange={(e) => changeDetails('postDepartment', e.target.value)}
                                    />
                                </li> : null
                        }
                        
                        { type !== PROFILE_TYPES.VIEW ?
                            <li className="profile__field">
                                <h3 className="profile__name">Пароль:</h3>
                                <input
                                    type="password"
                                    placeholder="Веведіть пароль"
                                    className="profile__value"
                                    value={details.password}
                                    onChange={(e) => changeDetails('password', e.target.value)}
                                />
                            </li> : null
                        }
                        {type !== PROFILE_TYPES.VIEW ?
                            <li className="profile__field">
                                <h3 className="profile__name">Роль:</h3>
                                <select
                                    className="profile__value"
                                    value={details.type}
                                    onChange={(e) => changeDetails('type', e.target.value)}>
                                    <option value="doctor">
                                        Doctor
                                    </option>
                                    <option value="psychologer">
                                        Psychologer
                                    </option>
                                    <option value="test_provider">
                                        Test provider
                                    </option>
                                    <option value="assistant">
                                        Assistant
                                    </option>
                                </select>
                            </li>:
                            null
                        }
                    </ul>
                </div>
                {
                    type !== PROFILE_TYPES.VIEW && touched ?
                        <div className="profile__controlls">
                            <button
                                className="profile__button button"
                                onClick={cancelHandler}
                            >
                                Скасувати
                            </button>
                            {type===PROFILE_TYPES.EDIT ?
                                <button
                                    className="profile__button button"
                                    onClick={updateHandler}
                                >
                                    Зберегти
                                </button>:
                                <button
                                    className="profile__button button"
                                    onClick={addHandler}
                                >
                                    Створити
                                </button>
                            }
                        </div>:
                        type === PROFILE_TYPES.VIEW ?
                            <button className="profile__button--center button" onClick={callback}>
                                Вийти
                            </button>:
                            null
                }
            </div>
        </section>

    return loading ? <Loading /> : renderContent()
}

export default Profile;
