import MaskedInput from 'react-text-mask';
import './positiveFields.styles.css';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LENGTH_MASK } from '../../constants/dataConstants';
import Autocomplete from '../autocomplete/autocomplete.component';
import { AuthContext } from '../../App';
import { getAllRequestCodes, getCities } from '../../services/recordsService';

function PositiveFields({individualData, changeIndividual}) {
    const userData = useContext(AuthContext)
    const [requestCodes, setRequestCodes] = useState([])
    const [cities, setCities] = useState([])

    useEffect(() => {
        getAllRequestCodes()
            .then(codes => {
                setRequestCodes(codes)
            })

        getCities()
            .then(data => {
                changeIndividual('city', data[0].id)
                setCities(data)
            })

    }, [setRequestCodes, setCities])

    const renderCities = () => {
        return cities.map(city =>
            <option value={city.id} key={city.id}>
                {city.name}
            </option>
        )
    }

    const autocompleteCodes = useMemo(() => requestCodes.map(code => ({
        id: code.id,
        label: code.code,
        description: code.description
    })))

    return (
        <>
            {userData.role === 'admin' ? <select
                className="filler__input brief__citySelector"
                value={individualData.cityId}
                onChange={(e) => changeIndividual('city', e.target.value)}>
                {renderCities()}
            </select> : null}
            <div className='appeal__controls'> 
                <label className="filler__label">
                    <p className='code__hint'>
                        3 букви прізвища
                    </p>
                    <MaskedInput
                        mask={LENGTH_MASK(3)}
                        type="text"
                        value={individualData.surname}
                        onChange={(e) => changeIndividual('surname', e.target.value)}
                        className='filler__textarea filler__input'
                    />
                </label>
                {/*eslint-disable-next-line jsx-a11y/label-has-associated-control*/}
                <label className="filler__label">
                    2 букви ім&apos;я
                    <MaskedInput
                        mask={LENGTH_MASK(2)}
                        value={individualData.name}
                        onChange={(e) => changeIndividual('name', e.target.value)}
                        type="text"
                        className="filler__input"       
                    />

                </label>
            </div>
            <div className='appeal__controls'>        
                {/*eslint-disable-next-line jsx-a11y/label-has-associated-control*/}
                <label className="filler__label">
                    <p className='code__hint'>
                        2 букви по батькові
                    </p>
                    <MaskedInput
                        mask={LENGTH_MASK(2)}
                        value={individualData.parentName}
                        onChange={(e) => changeIndividual('parentName', e.target.value)}
                        type="text"
                        className='filler__textarea filler__input'
                    />
                </label>
                <label className="filler__label">
                    Дата народження
                    <input
                        value={individualData.dateBirth}
                        onChange={(e) => changeIndividual('dateBirth', e.target.value)}
                        type="date"
                        className="filler__input"       
                    />

                </label>
            </div>
            <div className='appeal__controls'>      
                <label className="filler__label">
                    Стать:
                    <select
                        value={individualData.sex}
                        onChange={(e) => changeIndividual('sex', e.target.value)}
                        className="filler__input"       
                    >
                        <option value="ж">Жінка</option>
                        <option value="ч">Чоловік</option>
                    </select>
                </label>
                <Autocomplete 
                    currentValue={individualData.codeId}
                    emitter={(e) => changeIndividual('codeId', e)}
                    label={'Код направлення'}
                    list={autocompleteCodes}
                >
                </Autocomplete>
            </div>
            
            <div className='appeal__controls data__label'>
                <label className="filler__label">
                    Дата тестування
                    <input
                        value={individualData.date}
                        onChange={(e) => changeIndividual('date', e.target.value)}
                        type="date"
                        className="filler__input"
                    />
                </label>
            </div>

            <div className='appeal__controls'>
                <div className="simple__field">
                    <label className="simple__check">
                        <input type="checkbox"
                            checked={individualData.isCompleted}
                            onChange={() => changeIndividual('isCompleted', !individualData.isCompleted)}
                            hidden
                        />
                        <span className="simple__frame" />
                    </label>
                    <span className="label">Доведений до лікування</span>
                </div>
                <label className="filler__label">
                    Доведено до лікування
                    <input
                        value={individualData.completedText}
                        onChange={(e) => changeIndividual('completedText', e.target.value)}
                        type="date"
                        className='filler__textarea filler__input'
                    />
                </label>
            </div>
        </>
    );
}

export default PositiveFields;