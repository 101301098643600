import './autocomplete.styles.css';
import React, { useCallback, useState } from 'react';

const placeholder = 'Не обрано'

function Autocomplete({currentValue, emitter, list, label}) {
    const [currentInput, setCurrentInput] = useState('')

    const changeHandler = useCallback((value)=> {
        emitter(value)
        setCurrentInput(getCurrentCode(value, list))
    }, [setCurrentInput, getCurrentCode, emitter])

    
    const getCurrentCode = useCallback((value, codes) => {
        if(currentValue === -1) {
            return  'Не обрано'
        }

        const currentValue = value || currentValue

        if (currentValue === -1) {
            return  'Не обрано'
        }

        return codes
            .find(code => code.id === currentValue)?.label || ''
    }, [currentValue])
    
    const getOptions = () => {
        const options = list
            .filter(code => placeholder.startsWith(currentInput) || code.label.toString().startsWith(currentInput))
            .map(code =>
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <li
                    key={code.id}
                    title={code.description}
                    onClick={ ()=> 
                        changeHandler(code.id)}
                >
                    {code.label}
                </li>
            )

        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        options.unshift(<li
            key={-1}
            onClick={ ()=> changeHandler(-1)}
        >
            {placeholder}
        </li>)

        return options
    }


    return (
        <label className="filler__label">
            <p className='code__hint'>
                {label}
            </p>
            <div className="custom-select">
                <input 
                    value={currentInput}
                    onChange={(e) => setCurrentInput(e.target.value)} 
                    type="text" 
                    id="inputField" 
                    className="select-input filler__input"
                />
                <ul className="select-list">
                    {getOptions()}
                </ul>
            </div>
        </label>
    );
}

export default Autocomplete;