import EditableItem from '../../components/editableItem/editableItem.component';
import editting from '../../assets/images/editing.svg'
import deletting from '../../assets/images/delete.svg'
import lock from '../../assets/images/padlock.svg'
import unlock from '../../assets/images/unlock.svg'
import './customerList.styles.css';
import { useContext, useEffect, useState } from 'react';
import { deleteUser, getUsers, updateUserStatus } from '../../services/usersService';
import { ITEMS_PER_PAGE } from '../../constants/dataConstants';
import { useNavigate } from 'react-router-dom';
import { AdminRoutes } from '../../constants/pages';
import Loading from '../../components/loading/loading.component';
import { AlertContext } from '../../App';
import { SEVERITY_TYPE } from '../../constants/messages';
import React from 'react';

function CustomerList() {

    const [customers, setCustomers] = useState({
        users: [],
        totalPages: 0
    })
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(2)
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()

    useEffect(() => {
        getUsers(ITEMS_PER_PAGE, 0)
            .then(response => {
                setCustomers(response)
            })
            .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
            .finally(()=> setLoading(0))
    }, [setCustomers, setMessage])

    const loadMoreHandler = async () => {
        setLoading(1)
        const offset = currentPage * ITEMS_PER_PAGE
        try {
            const response = await getUsers(ITEMS_PER_PAGE, offset)
            setCustomers({
                users: [...customers.users, ...response.users],
                totalPages: response.totalPages
            })
            setCurrentPage(currentPage + 1)
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    }

    const deleteHadler = async (id) => {
        const confirmed = window.confirm('Ви впевнені, що бажаєте видалити?')
        if(confirmed) {
            setLoading(1)
            try {
                await deleteUser(id)
                const response = await getUsers(ITEMS_PER_PAGE, 0)
                setCustomers(response)
                setCurrentPage(1)
            } catch (e) {
                setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
            } finally {
                setLoading(0)
            }
        }
    }

    const blockHandler = async (id) => {
        const user = customers.users.find(user => user.id === id)
        setLoading(1)
        try {
            const updated = await updateUserStatus(id, !user.blocked)
            const newUsers = customers.users.map(user => {
                return user.id === updated.id ? updated : user
            })
            setCustomers({
                ...customers,
                users: newUsers
            })
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    }

    const addHandler = () => {
        navigate(AdminRoutes.CREATE_USER)
    }

    const renderUsers = () => {
        return customers.users.map((item, index) => {
            const statusIcon = item.blocked ? unlock : lock
            const title = item.blocked ? 'Розблокувати' : 'Заблокувати'
            return <li key={item.id}>
                <EditableItem
                    controlls={[statusIcon, editting, deletting]}
                    highlight={item.blocked}
                    callbacks={[
                        blockHandler.bind(null, item.id),
                        navigate.bind(null, `./${item.id}`),
                        deleteHadler.bind(null, item.id)
                    ]}
                    titles={[title]}
                    number={index + 1}
                >
                    <div className="customer__content">
                        <p className="customer__field">
                            {item.login}
                        </p>
                        <p className="customer__field">
                            {item.cridentials}
                        </p>
                        <p className="customer__field">
                            {item.phone}
                        </p>
                    </div>
                </EditableItem>
            </li>
        }
        )
    }

    const isAllItems = currentPage >= customers.totalPages

    const renderContent = () =>
        <section className='customerList'>
            <div className="container">
                <h2 className="customerList__title">Користувачі</h2>
                <button
                    className='customerList__adder button'
                    onClick={addHandler}
                >
                    Додати користувача
                </button>
                <ul className='customerList__list'>
                    {customers.users.length !== 0 ? renderUsers() : 'Немає Користувачів'}
                </ul>
                {   !isAllItems?
                    <button
                        className='customer__button button'
                        onClick={loadMoreHandler}
                    >
                        Більше
                    </button>:
                    null
                }
            </div>
        </section>
    return loading === 2 ? <Loading /> :
        loading === 1 ?
            <>
                {renderContent()}
                <Loading />
            </> :
            renderContent()
}

export default CustomerList;