import { HOST } from '../environment'

export async function getCities() {
    return fetch(`${HOST}/records/cities`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function getIndividual(pseudonim, type) {
    const response = await fetch(`${HOST}/records/individual?pseudonim=${pseudonim}&type=${type}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    const data = await response.json()
    if(response.status === 500) {
        throw new Error(data.message)
    }
    if(!data) {
        return null
    }
    return {
        status: response.status,
        data
    }
}

export async function getAnswerCases(type) {
    const response = await fetch(`${HOST}/records/answerCases?type=${type}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    const data = await response.json()
    if(!response.ok) {
        throw new Error(data.message)
    }
    return data
}

export async function createIndividual(individual) {
    const response = await fetch(`${HOST}/records/individuals`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(individual)
    })
    const data = await response.json()
    if(!response.ok) {
        throw new Error(data.message)
    }
    return data
}

export async function createRecord(record) {
    const response = await fetch(`${HOST}/records`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(record)
    })
    const data = await response.json()
    if(!response.ok) {
        throw new Error(data.message)
    }
    return data
}

export async function updateRecord(id, record) {
    const response = await fetch(`${HOST}/records/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(record)
    })
    const data = await response.json()
    if(!response.ok) {
        throw new Error(data.message)
    }
    return data
}

export async function getRecordDetails(id) {
    const response = await fetch(`${HOST}/records/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    })
    const data = await response.json()
    if(!response.ok) {
        throw new Error(data.message)
    }
    return data
}

export async function deleteRecord(id) {
    const response = await fetch(`${HOST}/records/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    })
    const data = await response.json()
    if(!response.ok) {
        throw new Error(data.message)
    }
    return data
}

export async function getRecords(limit, offset, isResult, role) {
    const response = await fetch(`${HOST}/records?limit=${limit}&offset=${offset}&isResult=${isResult}&role=${role}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    })
    const data = await response.json()
    if(!response.ok) {
        throw new Error(data.message)
    }
    return data
}

export async function getResults(role) {
    const response = await fetch(`${HOST}/records/results?role=${role}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    })
    const data = await response.json()
    if(!response.ok) {
        throw new Error(data.message)
    }
    return data
}

export const createAppeal = async (individual, appealRecord, questions) => {
    const data = await fetch(`${HOST}/records/appeal`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            individual,
            appealRecord,
            questions
        })
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const updateAppeal = async (appealId, individual, appealRecord, questions) => {
    const data = await fetch(`${HOST}/records/appeal/${appealId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            individual,
            appealRecord,
            questions
        })
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export const deleteAppeal = async (appealId) => {
    const data = await fetch(`${HOST}/records/appeal/${appealId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}

export async function getRecordsWithType(limit, offset, type, isResult, role) {
    const response = await fetch(`${HOST}/records?limit=${limit}&offset=${offset}&type=${type}&isResult=${isResult}&role=${role}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    })
    const data = await response.json()
    if(!response.ok) {
        throw new Error(data.message)
    }
    return data
}

export async function getAllRequestCodes() {
    const response = await fetch(`${HOST}/records/request-codes`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    })
    const data = await response.json()
    if(!response.ok) {
        throw new Error(data.message)
    }
    return data
}

export async function getIndividualByCase(caseCode) {
    const response = await fetch(`${HOST}/records/individual/search?code=${caseCode}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    })
    const data = await response.json()
    if(!response.ok) {
        throw new Error(data.message)
    }
    return data
}

export const createManualRecord = async (payload) => {
    const data = await fetch(`${HOST}/records/manually`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(payload)
    })
    if (!data.ok) {
        const message = await data.json()
        throw new Error(message.message)
    }
    return await data.json()
}
