

import { useNavigate } from 'react-router-dom';
import { AlertContext} from '../../App';
import BriefRecord from '../../components/briefRecord/briefRecord.component';
import Loading from '../../components/loading/loading.component';
import { EXISTS_CODE, INVALID_INDIVIDUAL, MANUAL_SUCCESS, SEVERITY_TYPE } from '../../constants/messages';
import { createManualRecord, getIndividualByCase, getResults } from '../../services/recordsService';

import './manualRecord.styles.css';

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PositiveFields from '../../components/positiveFields/positiveFields.component';
const today = new Date()

const defaultIndividual = {
    surname: '',
    name: '',
    parentName: '',
    date: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
    dateBirth: '',
    sex: 'ч',
    codeId: 1,
    caseCode: ''
}

function ManualRecord() {
    const [ loading, setLoading ] = useState(0)
    const [ page, setPage ] = useState(1) 
    const [ touched, setTouched] = useState(false)
    const [, setMessage ] = useContext(AlertContext)
    const [individualData, setIndividualData] = useState(defaultIndividual)
    const navigate = useNavigate()
    const [resultCases, setResultCases] = useState([])

    const changeIndividual = useCallback((field, value) => {
        setTouched(1)
        setIndividualData({
            ...individualData,
            [field]: value
        })
    })

    useEffect(() => {
        getResults('doctor')
            .then(results => {
                setResultCases(results)
            })
    }, [setResultCases])

    const positiveId = useMemo(() => {
        const result = resultCases.find(result => result.name === 'ВІЛ')?.id;
        return result ? [result] : []
    }, [resultCases])
    const negativeId = useMemo(() => {
        const result = resultCases.find(result => result.name === 'Негативний')?.id
        return result ? [result] : []
    }, [resultCases])

    const validateIndividual = useCallback(() => {
        return individualData.date
        && individualData.name
        && individualData.parentName
        && individualData.surname
    })

    const captilize =  useCallback((string) => {
        const lower = string.toLowerCase()
        return lower.charAt(0).toUpperCase() + lower.slice(1)
    })

    const searchHandler = useCallback(async () => {
        if (!validateIndividual()) {
            setMessage({message: INVALID_INDIVIDUAL, severity: SEVERITY_TYPE.ERROR})
            return;
        }

        setLoading(1)
        const date = individualData.dateBirth.split('-')
        let caseCode = [captilize(individualData.surname.substring(0, 3)),
            captilize(individualData.name.substring(0, 2)),
            captilize(individualData.parentName.substring(0, 2)),
            date[2],
            date[1],
            date[0].substring(0, 2),
            individualData.sex
        ].join('_')

        try {
            const result = await getIndividualByCase(caseCode)

            if (result.individual) {
                setMessage({message: EXISTS_CODE, severity: SEVERITY_TYPE.ERROR})
                return '';
            } else {
            
                return caseCode
            }
        } catch(e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    })

    const cancelHandler = () => {
        navigate('./..')
    }

    const createHandler = useCallback(async () => {
        setLoading(1)

        try {
            let caseCode = '';
            if(page === 0) {
                const result = await searchHandler()

                if (!result) return;

                caseCode = result;
            }
            await createManualRecord({
                ...individualData,
                caseCode,
                results: page === 0 ? positiveId : negativeId
            })
    
            setMessage({message: MANUAL_SUCCESS, severity: SEVERITY_TYPE.SUCCESS})

            const today = new Date()

            setIndividualData({
                ... defaultIndividual,
                date: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
            })
        } catch(e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    })


    const renderContent = () => {
        return <section className='manualRecord'>
            <div className="container">
                <h1 className="filler__title">Швидкий запис</h1>
                <div className='appeal__controls view__toggler'>
                    <div className="simple__field">
                        <label className="simple__check">
                            <input type="checkbox"
                                checked={page === 0}
                                onChange={() => setPage((page + 1)%2)}
                                hidden
                            />
                            <span className="simple__frame" />
                        </label>
                        <span className="label">Позитивний результат</span>
                    </div>
                </div>

            
                {page === 0 ? <>
                    <PositiveFields changeIndividual={changeIndividual} individualData={individualData}></PositiveFields>
                </> : <BriefRecord 
                    changeIndividual={changeIndividual} individualData={individualData}
                ></BriefRecord>}

                <div className="filler__controlls">
                    <button
                        className='filler__button button'
                        onClick={cancelHandler}
                    >
                        Скасувати
                    </button>
                    <button
                        className='filler__button button'
                        onClick={createHandler}
                        disabled={!touched}
                    >
                        Підтвердити
                    </button> 
                </div>
            
            </div>

        </section>

    }
        
    return loading === 2 ? <Loading /> :
        loading === 1 ?
            <>
                {renderContent()}
                <Loading />
            </>:
            renderContent();
}

export default ManualRecord;
