import './header.styles.css';
import logo from '../../assets/images/logo.svg'
import burger from '../../assets/images/burger.svg'
import cross from '../../assets/images/cross.svg'
import { useContext, useState } from 'react';
import { AuthContext } from '../../App';
import { Link } from 'react-router-dom'
import {
    adminNavigation,
    assistentNavigation,
    authNavigation,
    providerNavigation,
    psychologerNavigation,
    userNavigation
} from '../../constants/navigation';
import React from 'react';

function Header() {
    const [isOpened, setOpened] = useState(false)
    const userData = useContext(AuthContext)

    const navigationFactory = {
        'doctor': userNavigation,
        'admin': adminNavigation,
        'psychologer': psychologerNavigation,
        'test_provider': providerNavigation,
        'assistant': assistentNavigation
    }

    const navigation = userData ? navigationFactory[userData.role] : authNavigation

    const home = navigation.find((item) => item.type === 'index')

    const renderIcon = (index, path, src) =>
        <li className='header__navitem--icon' key={index}>
            <Link to={path} onClick={() => window.innerWidth < 800 && setOpened(!isOpened)}>
                <img src={src} alt="user" />
            </Link>
        </li>


    const renderLink = (index, path, label) =>
        <li className='header__navitem' key={index}>
            <Link to={path} onClick={() => window.innerWidth < 800 && setOpened(!isOpened)}>
                {label}
            </Link>
        </li>


    const renderNavList = () => {
        const result =  navigation.map((item, index) => {
            if(item.type === 'link') {
                return renderLink(index, item.path, item.label)
            }
            if(item.type === 'icon') {
                return renderIcon(index, item.path, item.src)
            }
            return null
        })
        return result.filter(item => !!item)
    }

    const navlist = renderNavList()

    const navClass = !isOpened ? 'header__navbar' : 'header__navbar--shown'

    return (
        <header>
            <div className="container header__inner">
                <Link className='header__preview' to={home.path} >
                    <img src={logo} alt='logo' className='header__logo' />
                    <p className='header__title'>
                        МІЖНАРОДНИЙ ІНСТИТУТ <br/>
                        ПРОБЛЕМ ВІЛ/СНІДУ ТА ТУБЕРКУЛЬОЗУ
                    </p>
                </Link>
                <nav className={navClass}>
                    <button
                        className='header__cross'
                        onClick={() => setOpened(!isOpened)}
                    >
                        <img src={cross} alt="" />
                    </button>
                    <ul
                        className='header__navlist'
                    >
                        {navlist}
                    </ul>
                </nav>
                {
                    navlist.length > 0 &&
                    <button
                        className='header__burger'
                        onClick={() => setOpened(!isOpened)}
                    >
                        <img src={burger} alt="" />
                    </button>
                }
            </div>
        </header>
    );
}

export default Header;