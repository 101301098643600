import editting from '../../assets/images/editing.svg';
import deletting from '../../assets/images/delete.svg';
import './testPlaceList.styles.css';
import { useContext, useEffect, useState } from 'react';
import { getQuestionares} from '../../services/questionareService';
import { ITEMS_PER_PAGE, QUESTIONARES_TYPES } from '../../constants/dataConstants';
import { useNavigate } from 'react-router-dom';
import { AdminRoutes } from '../../constants/pages';
import Loading from '../../components/loading/loading.component';
import { SEVERITY_TYPE } from '../../constants/messages';
import React from 'react';
import { deletePlace, getPlaces } from '../../services/testService';
import { AlertContext } from '../../App';
import Table from '../../components/table/table.component';

function TestPlacesList({ type }) {
    const [places, setPlaces] = useState({
        places: [],
        totalPages: 0
    })
    const [loading, setLoading] = useState(2)
    const [currentPage, setCurrentPage] = useState(1)
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()

    useEffect(() => {
        getPlaces(1, ITEMS_PER_PAGE)
            .then(response => {
                setPlaces(response)
            })
            .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
            .finally(() => setLoading(0))
    }, [setPlaces, setLoading, setMessage])

    const deleteHandler = async (id) => {
        const confirmed = window.confirm('Впевнені, що бажаєте видалити?')
        if(confirmed) {
            setLoading(1)
            try {
                await deletePlace(id)
                const response = await getPlaces(1, ITEMS_PER_PAGE)
                setPlaces(response)
                setCurrentPage(1)
            } catch (e) {
                setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
            } finally {
                setLoading(0)
            }
        }
    }

    const loadMoreHandler = async () => {
        setLoading(1) 
        try {
            const response = await getQuestionares(ITEMS_PER_PAGE, currentPage + 1)
            setPlaces({
                questionares: [...places.places, ...response.places],
                totalPages: response.totalPages
            })
            setCurrentPage(currentPage + 1)
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    }

    const addHandler = () => {
        navigate(AdminRoutes.CREATE_PLACE)
    }

    const titles = ['Адреса', 'Місто', 'Отримувач', '']

    const placePattern = (place) => 
        <>
            <p>
                {place.address}
            </p>
            <p>
                {place.cityName}
            </p>
            <p>
                {place.reciever}
            </p>
            <div>
                <button className='icon' onClick={() => navigate(`./${place.id}`)}>
                    <img src={editting} alt="" />
                </button>
                <button className='icon' onClick={() => deleteHandler(place.id)}>
                    <img src={deletting} alt="" />
                </button>
            </div>
        </>

    const isAllItems = currentPage >= places.totalPages

    const renderContent = () =>
        <section className='questionnaireList'>
            <div className="container">
                <h2 className="questionnaireList__title">
                   Місця тестування
                </h2>
                {type === QUESTIONARES_TYPES.EDIT ?
                    <button
                        className='questionnaireList__adder button'
                        onClick={addHandler}
                    >
                        Додати місце тестування
                    </button>
                    : null
                }
                <Table
                    data={places.places}
                    emptyLabel="Немає місць тестування"
                    pattern={placePattern}
                    titles={titles}
                />
                {!isAllItems ?
                    <button
                        className='questionnaireList__button button'
                        onClick={loadMoreHandler}
                    >
                        Більше
                    </button> :
                    null
                }
            </div>
        </section>

    return loading === 2 ? <Loading />
        : loading === 1 ?
            <>
                {renderContent()}
                <Loading />
            </>:
            renderContent();
}

export default TestPlacesList;