import { HOST } from '../environment'

export function checkAuthed() {
    const cookie = document.cookie.split('; ')
    const token = cookie.find(item => item.split('=')[0]==='token')
    if(!token) {
        return null
    }
    const encodeData = token.split('=')[1]
    if(!encodeData) {
        return null
    }
    const data = decodeURIComponent(token.split('=')[1])
    return JSON.parse(data.substring(2))
}

export function makeLogout() {
    document.cookie = 'token='
}

export async function sendLoginData(login, password) {
    return fetch(`${HOST}/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            login,
            password
        })
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function registerUser(data) {
    return fetch(`${HOST}/auth/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}