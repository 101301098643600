import EditableItem from '../../components/editableItem/editableItem.component';
import plus from '../../assets/images/plus.svg'
import './filledList.styles.css';
import { useContext, useEffect, useState } from 'react';
import { getQuestionares, JSONdateToFormat } from '../../services/questionareService';
import { ITEMS_PER_PAGE } from '../../constants/dataConstants';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/loading/loading.component';
import { AlertContext } from '../../App';
import { SEVERITY_TYPE } from '../../constants/messages';
import React from 'react';

function FilledList() {

    const [questionares, setQuestionnares] = useState({
        questionares: [],
        totalPages: 0
    })
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(2)
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()

    useEffect(() => {
        getQuestionares(ITEMS_PER_PAGE, 0, true)
            .then(response => {
                setQuestionnares(response)
            })
            .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
            .finally(()=>setLoading(0))
    }, [setQuestionnares, setMessage])

    const loadMoreHandler = async () => {
        setLoading(1)
        const offset = currentPage * ITEMS_PER_PAGE
        try {
            const response = await getQuestionares(ITEMS_PER_PAGE, offset, true)
            setQuestionnares({
                questionares: [...questionares.questionares, ...response.questionares],
                totalPages: response.totalPages
            })
            setCurrentPage(currentPage + 1)
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    }

    const renderQuestionares = () => {
        return questionares.questionares.map((item, index) =>
            <li key={item.id}>
                <EditableItem
                    controlls={[plus]}
                    callbacks={[navigate.bind(null, `./${item.id}`)]}
                    number={index + 1}
                >
                    <div className="filled__content">
                        <p className="filled__field">
                            {item.name}
                        </p>
                        <p className="filled__field">
                            {JSONdateToFormat(item.filledDate)}
                        </p>
                    </div>
                </EditableItem>
            </li>
        )
    }

    const isAllItems = currentPage >= questionares.totalPages

    const renderContent = () =>
        <section className='filledList'>
            <div className="container">
                <h2 className="filledList__title">Заповнити анкету</h2>
                <ul className='filledList__list'>
                    {questionares.questionares.length !== 0 ? renderQuestionares() : 'Немає анкет'}
                </ul>
                {
                    !isAllItems ?
                        <button
                            className='filled__button button'
                            onClick={loadMoreHandler}
                        >
                            Більше
                        </button>:
                        null
                }
            </div>
        </section>

    return loading === 2 ? <Loading /> :
        loading === 1 ?
            <>
                {renderContent()}
                <Loading />
            </>:
            renderContent();
}

export default FilledList;