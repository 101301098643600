import { useContext } from 'react';
import { AlertContext } from '../../App';
import { SEVERITY_TYPE } from '../../constants/messages';
import './alert.styles.css';
import React from 'react';

function Alert() {
    const [message, setMessage] = useContext(AlertContext)

    const classFactory = {
        [SEVERITY_TYPE.WARNING]: 'alert--warning',
        [SEVERITY_TYPE.ERROR]: 'alert--error',
        [SEVERITY_TYPE.SUCCESS]: 'alert--success',
    }

    const messageFactory = {
        [SEVERITY_TYPE.WARNING]: 'Попередження!',
        [SEVERITY_TYPE.ERROR]: 'Помилка!',
        [SEVERITY_TYPE.SUCCESS]: 'Успішно!',
    }

    return (
        <div className='alert'>
            <h2 className={classFactory[message.severity]}>
                {messageFactory[message.severity]}
            </h2>
            <div className='alert__body'>
                {message.message}
            </div>
            <button
                className='button alert__button'
                onClick={()=>setMessage({message: null, severity: SEVERITY_TYPE.NONE})}
            >
                OK
            </button>
        </div>
    );
}

export default Alert;