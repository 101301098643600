import { useContext, useEffect, useState } from 'react';
import { AlertContext } from '../../App';
import { SEVERITY_TYPE } from '../../constants/messages';
import { getAnswerCases } from '../../services/recordsService';
import Loading from '../loading/loading.component';
import './simpleQuestion.styles.css';
import React from 'react';

function SimpleQuestion({question, onChange, value: inputValue }) {
    const [answerCases, setAnswerCases] = useState([])
    const [loading, setLoading] = useState(true)
    const [, setMessage] = useContext(AlertContext)

    useEffect(() => {
        getAnswerCases(question.answerType)
            .then(response => setAnswerCases(response))
            .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
            .finally(setLoading(false))
    }, [question, setMessage])

    const changeHandler = (e) => {
        const value = e.target.value
        if(question.answerType === 2) {
            onChange(question.questionId, answerCases[0].id, answerCases[0].answerText, value)
        } else {
            const answerText = answerCases.find(answerCase => answerCase.id === +value).answerText
            onChange(question.questionId, +value, answerText)
        }
    }

    const firstType = (answerCase) =>
        <div className="simple__field" key={answerCase.id}>
            <label className="simple__check">
                <input type="radio"
                    hidden
                    name={question.questionId}
                    value={answerCase.id}
                    onChange={changeHandler}
                    checked={answerCase.id === inputValue}
                />
                <span className="simple__frame" />
            </label>
            {answerCase.answerText}
        </div>

    const secondType = (answerCase) =>
        <label className="simple__check--full" key={answerCase.id}>
            <input
                type="text"
                className="auth__input"
                name={question.questionId}
                value={inputValue || ''}
                placeholder={answerCase.answerText}
                onChange={changeHandler}
            />
        </label>

    const patternFactory = {
        1: firstType,
        2: secondType
    }

    const renderAnswers = () => {
        return answerCases.map(answerCase => patternFactory[question.answerType](answerCase))
    }

    const renderContent = () =>
        <div className="simple">
            <p className="simple__question">
                {question.question}
            </p>
            <div className="simple__controlls">
                {renderAnswers()}
            </div>
        </div>

    return loading ? <Loading /> : renderContent();
}

export default SimpleQuestion;