import { STATUS_FACTORY } from '../constants/dataConstants'
import { HOST } from '../environment'
import React from 'react'

export async function getAppeals(size, pageNumber, name) {
    return fetch(`${HOST}/patient/appeals?size=${size}&pageNumber=${pageNumber}&name=${name}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function getAppeal(id, code) {
    return fetch(`${HOST}/patient/appeals/${id}${code ? `?code=${code}`: ''}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function createSession(data) {
    return fetch(`${HOST}/patient/sessions/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function updateSession(id, data) {
    return fetch(`${HOST}/patient/sessions/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function deleteSession(id) {
    return fetch(`${HOST}/patient/sessions/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function getSessions(size, pageNumber, name, status) {
    return fetch(`${HOST}/patient/sessions?size=${size}&pageNumber=${pageNumber}&name=${name || ''}&status=${status || ''}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function getSessionDetails(id) {
    return fetch(`${HOST}/patient/sessions/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function getSessionStatistics(dateStart, dateEnd) {
    return fetch(`${HOST}/patient/sessions/stats?startDate=${dateStart}&endDate=${dateEnd}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function updateStatus(id, status) {
    return fetch(`${HOST}/patient/appeals/${id}/status`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            status
        })
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export const renderStatuses = () => {
    const keys = Object.keys(STATUS_FACTORY)
    return keys.map(key => 
        <option key={key} value={key}>{STATUS_FACTORY[key]}</option>)
}

export const getStatus = (index) => {
    const keys = Object.keys(STATUS_FACTORY)
    return keys[index]
}