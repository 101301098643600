import { useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import { createSession, getAppeal, getSessionDetails, renderStatuses, updateSession, updateStatus } from '../../services/appealService';
import { getCurrentPathId } from '../../services/routerService';
import { JSONdateToFormat } from '../../services/questionareService'
import { APPEAL_TYPES, DURATION_MASK } from '../../constants/dataConstants'
import { INVALID_CONFIRMED_CODE, SEVERITY_TYPE, SUCCESS_SESSION_UPDATED_MESSAGE } from '../../constants/messages';
import './appealFiller.styles.css';
import { AlertContext } from '../../App';
import Loading from '../../components/loading/loading.component';
import MaskedInput from 'react-text-mask';
import { useNavigate } from 'react-router-dom';
import React from 'react';

function AppealFiller({ type }) {
    const [appeal, setAppeal] = useState()
    const [session, setSession] = useState({
        comment: '',
        duration: '',
        request: ''
    })
    const [loading, setLoading] = useState(true)
    const [touched, setTouched] = useState(false)
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()
    
    const changeComment = event => {
        setTouched(true)
        setSession({
            ...session,
            comment: event.target.value
        })
    }

    const changeStatus = event => {
        setTouched(true)
        setAppeal({
            ...appeal,
            status: event.target.value
        })
    }

    const changeRequest = event => {
        setTouched(true)
        setSession({
            ...session,
            request: event.target.value
        })
    }

    const changeDuration = event => {
        setTouched(true)
        setSession({
            ...session,
            duration: event.target.value
        })
    }

    const changeCode = event => {
        setAppeal({
            ...appeal,
            code: event.target.value
        })
    }

    const parseToDuration = (string) => {
        const parts = string
            .split('. ')
            .filter(part => !!part)
            .map(part => part.substring(0, 2))
        let measure = 1
        let duration = 0
        parts.forEach(part => {
            const digit = /^\d+$/.test(part) ? +part : 0
            duration +=  digit * Math.pow(60, measure)
            measure--
        })
        
        return duration
    }

    const durationToString = (duration) => {
        let hours = Math.floor(duration / 60)
        let minutes = duration -  (hours * 60)
        hours = hours.toString()
        minutes = minutes.toString()
        hours = `${hours[0] || '0'}`
        minutes = `${minutes[1] ? minutes[0] : '0'}${minutes[1] || minutes[0] || '0'}`

        return `${hours}год. ${minutes}хв.`
    }

    const addHandler = async () => {
        try {
            setLoading(true)
            await createSession(
                {   
                    comment: session.comment,
                    patientRequest: session.request,
                    duration: parseToDuration(session.duration),
                    appeal: appeal.id
                }
            )
    
            await updateStatus(appeal.id, appeal.status)
            navigate('./../')
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(false)
        }
    }

    const updateHandler = async () => {
        try {
            setLoading(true)
            await updateSession(
                session.id,
                {   
                    comment: session.comment,
                    patientRequest: session.request,
                    duration: parseToDuration(session.duration),
                    appeal: appeal.id
                }
            )
    
            await updateStatus(appeal.id, appeal.status)
            setTouched(false)
            setMessage({message: SUCCESS_SESSION_UPDATED_MESSAGE, severity: SEVERITY_TYPE.SUCCESS})
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(false)
        }
    }

    const calncelHandler = () => {
        navigate('./../')
    }

    const renderQuestions = () => {
        return appeal.questions.map((question, index) => 
            <li className="question__field" key={question.id}>
                <i>{index + 1}</i>
                <h3 className="profile__name">{question.text}</h3>
            </li>)
    }

    const dataFetcher = useCallback((code) => {
        const id = getCurrentPathId()
        let promise
        if(type === APPEAL_TYPES.EDIT) {
            promise = getSessionDetails(id)
        } else {
            promise = getAppeal(id, code)
        }
        
        promise
            .then(data => {
                if(type === APPEAL_TYPES.CREATOR) {
                    if(data.type === 'NO_CODE') {
                        setAppeal({
                            type: data.type,
                            code: ''
                        })
                    } else if (data.type === 'INVALID_CODE') {
                        setAppeal({
                            type: data.type,
                            code: ''
                        })
                        setMessage({ message: INVALID_CONFIRMED_CODE, severity: SEVERITY_TYPE.ERROR })
                    } else {
                        setAppeal(data)
                    }
                }
                if(type === APPEAL_TYPES.EDIT) {
                    setSession({
                        id: data.session.id,
                        comment: data.session.comment,
                        request: data.session.patientRequest,
                        duration: durationToString(data.session.duration)
                    })
                    setAppeal(data.appeal)
                }
            })
            .catch((e) => {
                setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})})
            .finally(() => setLoading(false))
    }, [])

    const sendCode = () => {
        if(!appeal.code || appeal.code.length !== 5) {
            setMessage({message: INVALID_CONFIRMED_CODE, severity: SEVERITY_TYPE.ERROR})
            return
        }
        setLoading(true)
        dataFetcher(appeal.code)
    }

    useEffect(() => {
        dataFetcher()
    }, [dataFetcher])

    const codeConfiramtion = () =>
        <div className='confirm-container'>
            <label>
                Код підтвердження:
                <input type="text" className='confiramtion-input' value={appeal.code} onChange={changeCode} />
            </label>
            <button onClick={sendCode} className="profile__button button">Підтвердити</button>
        </div>

    const renderContent = () => {
        if(!appeal) {
            return
        }

        if(appeal.type) {
            return codeConfiramtion()
        }
        return <section className='profile'>
            <div className='container'>
                {appeal.questions.length ? 
                    <div className='appeal__inner'>
                        <h2 className="profile__title">Питання</h2>
                        <ul className="appeal__questions">
                            {renderQuestions()}
                        </ul>
                    </div> : null
                }
                
                <div className='appeal__inner'>
                    <h2 className="profile__title">Сеанс</h2>
                    <ul className="profile__content">
                        <li className="profile__field">
                            <h3 className="profile__name">Дата:</h3>
                            <p className="appeal__value">{JSONdateToFormat(appeal.createDate)}</p>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Псевдонім:</h3>
                            <p className="appeal__value">{appeal.pseudonim}</p>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Статус:</h3>
                            <select
                                className="appeal__value appeal__select"
                                value={appeal.status}
                                onChange={changeStatus}
                            >
                                {renderStatuses()}
                            </select>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Вікова категорія:</h3>
                            <p className="appeal__value">{appeal.birthDate}</p>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Запит клієнта:</h3>
                            <textarea
                                className="appeal__value"
                                value={session.request}
                                onChange={changeRequest}
                            >
                            </textarea>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Тривалість:</h3>
                            <MaskedInput className="appeal__value"
                                mask={DURATION_MASK}
                                value={session.duration}
                                onChange={changeDuration}
                            >
                            </MaskedInput>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Результат сеансу:</h3>
                            <textarea
                                className="appeal__value"
                                value={session.comment}
                                onChange={changeComment}
                            >
                            </textarea>
                        </li>
                    </ul>
                </div>

                <div className="profile__controlls">
                    <button
                        className="profile__button button"
                        onClick={calncelHandler}
                    >
                        {touched ? 'Скасувати' : 'Назад'}
                    </button>
                    {type===APPEAL_TYPES.EDIT ?
                        <button
                            className="profile__button button"
                            onClick={updateHandler}
                            disabled={!touched}
                        >
                            Зберегти
                        </button>:
                        <button
                            className="profile__button button"
                            onClick={addHandler}
                            disabled={!touched}
                        >
                            Створити
                        </button>
                    }
                </div>

            </div>
        </section>
    }

    return loading ? <Loading /> : renderContent()
}

export default AppealFiller;