import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertContext } from '../../App';
import editting from '../../assets/images/editing.svg';
import deletting from '../../assets/images/delete.svg';
import search from '../../assets/images/search.svg';
import plus from '../../assets/images/plus.svg';
import filterIcon from '../../assets/images/filter.svg';
import Loading from '../../components/loading/loading.component';
import { ITEMS_PER_PAGE, STATUS_FACTORY } from '../../constants/dataConstants';
import { DELETE_MESSAGE, SEVERITY_TYPE } from '../../constants/messages';
import { deleteSession, getSessions, renderStatuses } from '../../services/appealService';
import { debounce } from '../../util';
import './sessionList.styles.css';
import { JSONdateToFormat } from '../../services/questionareService';
import Table from '../../components/table/table.component';
import React from 'react';

function SessionList() {
    const [session, setSession] = useState({
        sessions: [],
        totalPages: 0
    })

    const [query, setQuery] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(2)
    const [filter, setFilter] = useState('')
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()

    const sender = useCallback(debounce(async (query) => {
        setLoading(1)
        try {
            const response = await getSessions(ITEMS_PER_PAGE, 1, query)
            setSession(response)
            setCurrentPage(1)
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    }, 500), [setSession, setCurrentPage])

    const searchHandler = (e) => {
        const value = e.target.value
        setQuery(value)
        sender(value)
    }

    useEffect(() => {   
        getSessions(ITEMS_PER_PAGE, 1, '')
            .then(response => {
                setSession(response)
            })
            .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
            .finally(()=> setLoading(0))
    }, [getSessions, setMessage])

    const filterHandler = async (e) => {
        const value = e.target.value
        setFilter(value)
        setLoading(1)
        try {
            const response = await getSessions(ITEMS_PER_PAGE, 1, query, value)
            setSession(response)
            setCurrentPage(1)
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    }

    const loadMoreHandler = async () => {
        setLoading(1)
        try {
            const response = await getSessions(ITEMS_PER_PAGE, currentPage + 1, query, filter)
            setSession({
                sessions: [...session.sessions, ...response.sessions],
                totalPages: response.totalPages
            })
            setCurrentPage(currentPage + 1)
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    }

    const deleteHadler = async (id) => {
        const confirmed = window.confirm(DELETE_MESSAGE)
        if(confirmed) {
            setLoading(1)
            try {
                await deleteSession(id)
                const response = await getSessions(ITEMS_PER_PAGE, 1, query, filter)
                setSession(response)
                setCurrentPage(1)
            } catch(e) {
                setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
            } finally {
                setLoading(0)
            }
        }
    }

    const sessionPattern = (session) => 
        <>
            <p>
                <span className="label">Дата: </span>
                {JSONdateToFormat(session.date)}
            </p>
            <p>
                <span className="label">Псевдонім: </span>
                {session.pseudonim}
            </p>
            <p>
                <span className="label">Статус: </span>
                {STATUS_FACTORY[session.status]}
            </p>
            <p>
                <span className="label">Вікова категорія: </span>
                {session.age}
            </p>
            <div>
                {
                    session.sessionCount < 5 ?
                        <button className='icon' onClick={() => navigate(`/appeals/${session.appealId}`)}>
                            <img src={plus} alt="" />
                        </button> : null
                }
                <button className='icon' onClick={() => navigate(`./${session.id}`)}>
                    <img src={editting} alt="" />
                </button>
                <button className='icon' onClick={() => deleteHadler(session.id)}>
                    <img src={deletting} alt="" />
                </button>
            </div>
        </>

    const isAllItems = currentPage >= session.totalPages
    const titles = ['Дата', 'Псевдонім', 'Статус', 'Вікова категорія', '']

    const renderContent = () => {
        if(!session.sessions) {
            return
        }

        return <section className='filledList'>
            <div className="container">
                <h2 className="filledList__title">Сеанси</h2>
                <div className="recordList__controlls">
                    <div className='recordList__searcher'>
                        <img src={search} alt="" className='recordList__icon' />
                        <input
                            type='text'
                            className='recordList__input'
                            placeholder='Пошук'
                            value={query}
                            onChange={searchHandler}
                        />
                    </div>
                    <div className='recordList__searcher'>
                        <img src={filterIcon} alt="" className='recordList__icon' />
                        <select
                            className='recordList__input'
                            value={filter}
                            onChange={filterHandler}
                        >
                            {renderStatuses()}
                            <option value={''}>Усі</option>
                        </select>
                    </div>
                </div>
                <Table 
                    titles={titles}
                    emptyLabel="Немає сеансів"
                    data={session.sessions}
                    pattern={sessionPattern}
                />
                {
                    !isAllItems ?
                        <button
                            className='filled__button button'
                            onClick={loadMoreHandler}
                        >
                            Більше
                        </button>:
                        null
                }
            </div>
        </section>

    }
        
    return loading === 2 ? <Loading /> :
        loading === 1 ?
            <>
                {renderContent()}
                <Loading />
            </>:
            renderContent();
}

export default SessionList;