import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertContext } from '../../App';
import search from '../../assets/images/search.svg';
import EditableItem from '../../components/editableItem/editableItem.component';
import Loading from '../../components/loading/loading.component';
import { ITEMS_PER_PAGE } from '../../constants/dataConstants';
import { SEVERITY_TYPE } from '../../constants/messages';
import { getAppeals } from '../../services/appealService';
import { JSONdateToFormat } from '../../services/questionareService';
import { debounce } from '../../util';
import './appealsList.styles.css';
import React from 'react';

function AppealList() {
    const [appeals, setAppeals] = useState({
        appeals: [],
        totalPages: 0
    })

    const [query, setQuery] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(2)   
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()

    const sender = useCallback(debounce(async (query) => {
        setLoading(1)
        try {
            const response = await getAppeals(ITEMS_PER_PAGE, 1, query)
            setAppeals(response)
            setCurrentPage(1)
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    }, 500), [setAppeals, setCurrentPage])

    const searchHandler = (e) => {
        const value = e.target.value
        setQuery(value)
        sender(value)
    }

    useEffect(() => {   
        getAppeals(ITEMS_PER_PAGE, 1, '')
            .then(response => {
                setAppeals(response)
            })
            .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
            .finally(()=> setLoading(0))
    }, [getAppeals, setMessage])

    const loadMoreHandler = async () => {
        setLoading(1)
        try {
            const response = await getAppeals(ITEMS_PER_PAGE, currentPage + 1, query)
            setAppeals({
                appeals: [...appeals.appeals, ...response.apepals],
                totalPages: response.totalPages
            })
            setCurrentPage(currentPage + 1)
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    }

    const renderAppeals = () => {
        return appeals.appeals.map((item, index) =>
            <li key={item.id}>
                <EditableItem
                    isText
                    controlls={['Створити сеанс']}
                    callbacks={[navigate.bind(null, `./${item.id}`)]}
                    number={index + 1}
                >
                    <div className="filled__content">
                        <p className="filled__field">
                            {item.pseudonim}
                        </p>
                        <p className="filled__field">
                            Дата: {JSONdateToFormat(item.date, true)}
                        </p>
                        <p className="filled__field">
                            Номер: {item.phone.slice(-4).padStart(5, '*')}
                        </p>
                        <p className="filled__field">
                            Дата народження: {item.birthDate}
                        </p>
                    </div>
                </EditableItem>
            </li>
        )
    }

    const isAllItems = currentPage >= appeals.totalPages

    const renderContent = () =>
        <section className='filledList'>
            <div className="container">
                <h2 className="filledList__title">Завернення</h2>
                <div className='recordList__searcher'>
                    <img src={search} alt="" className='recordList__icon' />
                    <input
                        type='text'
                        className='recordList__input'
                        placeholder='Пошук'
                        value={query}
                        onChange={searchHandler}
                    />
                </div>
                <ul className='filledList__list appeal__list'>
                    {appeals.appeals.length !== 0 ? renderAppeals() : 'Немає звернень'}
                </ul>
                {
                    !isAllItems ?
                        <button
                            className='filled__button button'
                            onClick={loadMoreHandler}
                        >
                            Більше
                        </button>:
                        null
                }
            </div>
        </section>

    return loading === 2 ? <Loading /> :
        loading === 1 ?
            <>
                {renderContent()}
                <Loading />
            </>:
            renderContent();
}

export default AppealList;