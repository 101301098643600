import { useContext } from 'react';
import { AuthContext } from '../../App';
import { ADMIN_INSTRUCTURES, DOCTOR_INSTRUCTURES } from '../../constants/instructions';
import './home.styles.css';
import React from 'react';

function Home() {
    const user = useContext(AuthContext)
    const instruction = user.role === 'admin' ? ADMIN_INSTRUCTURES : DOCTOR_INSTRUCTURES

    const gulugulu = () => {
        return instruction.map((instruction, index) => {
            const paragraphClass = index === 0 ? 'home__paragraph--bold' : 'home__paragraph'
            return <p className={paragraphClass} key={index}>
                <h3 className="home__title">{instruction.title}</h3>
                {instruction.data}
            </p>
        }
        )
    }

    return (
        <section className='home'>
            <div className='container'>
                <div className="home__inner">
                    {gulugulu()}
                </div>
            </div>
        </section>
    );
}

export default Home;