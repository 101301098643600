export const IndividualMessages = {
    POSITIVE: 'Дана персона вже має позитивний результат з цієї анкети',
    NEGATIVE: 'Дана персона вже проходила опитування. Перезаписати?',
    EXISTING: 'Веведно існуючий псевдонім'
}

export const REQUIRED_MESSAGE = 'Введіть усі дані для користувача'
export const PHONE_MESSAGE = 'Введіть телефон правильно'
export const INVALID_DATE = 'Кінцева дата має бути більша за початкову'
export const INVALID_INDIVIDUAL_DATA = 'Введено неправильні короистувача'
export const INVALID_PHONE = 'Введено неправильний номер телефону'

export const DELETE_MESSAGE = 'Впевнені, що бажаєте видалити?'
export const SUCCESS_SESSION_UPDATED_MESSAGE = 'Сеанс успішно оновлено'
export const EXISTED_SESSION_MESSAGE = 'Психолог вже опрацював дану анкету, змінювати діагноз неможна'
export const INVALID_CONFIRMED_CODE = 'Код повинен мати 5 символів'
export const SELECTED_FOR_PSYCHOLOGER_MESSAGE = 'Пацієнт обраний для напралення до психолога, зміна діагнозу неможлива. Вимкнути опцію направлення до психолога?'
export const INVALID_PLACE_DATA = 'Введіть коректні дані для місця тестування'
export const SUCCESS_PLACE_UPDATED_MESSAGE = 'Місце тестування успішно оновлено'
export const NO_SUPPLY_MESSAGE = 'Немає запиту постачання'
export const EXISTS_CODE = 'Даний пацієнт вже існує'
export const INVALID_INDIVIDUAL = 'Введіть усі поля'
export const MANUAL_SUCCESS = 'Запис успішно додано'

export const SEVERITY_TYPE = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    NONE: 'none'
}
