import { HOST } from '../environment'

export async function getStats(id, city, startDate, endDate) {
    return fetch(`${HOST}/questionareTypes/${id}/stats?city=${city}&dateStart=${startDate}&dateEnd=${endDate}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export async function getStatsName(id) {
    return fetch(`${HOST}/questionareTypes/${id}/name`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    }).then(async data => {
        if(!data.ok) {
            const message = await data.json()
            throw new Error(message.message)
        }
        return data.json()
    })
}

export function downloadStats(city, startDate, endDate) {
    const cityFilter = city ? `city=${city}&` : ''
    return `${HOST}/questionareTypes/stats/download?${cityFilter}dateStart=${startDate}&dateEnd=${endDate}`
}

export function downloaQuantitydStats(city, startDate, endDate) {
    const cityFilter = city ? `city=${city}&` : ''
    return `${HOST}/questionareTypes/statsQuantity/download?${cityFilter}dateStart=${startDate}&dateEnd=${endDate}`
}