import './table.styles.css';
import React from 'react';

function Table({ titles, emptyLabel, pattern, data }) {

    const generateTitles = () => titles.map((title, index) => <p key={index} className="table__field">{ title }</p>)


    const generateData = () => data.map(( item, index ) =>
        <li key={item.id || index} className="table__record">
            <i className="index">{ index + 1 }</i>
            { pattern(item) }
        </li>)

    return (
        <div className='table'>
            <li className='table__head'>
                { generateTitles() }
            </li>
            <ul className="table__body">
                {data.length ? generateData() : 
                    <li className='table__empty'>{ emptyLabel }</li>}
            </ul>
        </div>
    );
}

export default Table;