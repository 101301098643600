import { createContext, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Alert from './components/alert/alert.component';
import Auth from './components/auth/auth.component';
import Footer from './components/footer/footer.component';
import Header from './components/header/header.component';
import { APPEAL_TYPES, PROFILE_TYPES, QUESTIONARES_TYPES, TEST_PLACE, TEST_SUPPLY } from './constants/dataConstants';
import { SEVERITY_TYPE } from './constants/messages';
import { AdminRoutes, AssistentRoutes, AuthRoutes, ProviderRoutes, PsychologerRoutes, UserRoutes} from './constants/pages';
import AppealFiller from './pages/appealFiller/appealFiller.component';
import AppealList from './pages/appealsList/appealsList.component';
import CustomerList from './pages/customerList/customerList.component';
import FilledList from './pages/filledList/filledList.component';
import Home from './pages/home/home.component';
import Profile from './pages/profile/profile.component';
import QuestionnaireCreator from './pages/questionnaireCreator/questionnaireCreator.component';
import QuestionnaireFiller from './pages/questionnaireFiller/questionnaireFiller.component';
import QuestionnaireList from './pages/questionnaireList/questionnaireList.component';
import RecordList from './pages/recordList/recordList.component';
import SessionList from './pages/sessionList/sessionList.component';
import Statistic from './pages/statistic/statistic.component';
import { checkAuthed, makeLogout } from './services/authService';
import React from 'react';
import TestPlacesList from './pages/testPlaces/testPlaceList.compoenent';
import PlaceFiller from './pages/placeFiller/placeFiller.component';
import TestSupplyList from './pages/testSupplies/testSupplyList';
import SupplyFiller from './pages/supplyFiller/supplyFiller.component';
import ManualRecord from './pages/manualRecord/manualRecord.component';

export const AuthContext = createContext(null);
export const AlertContext = createContext();

let alertData = {
    message: null,
    severity: SEVERITY_TYPE.NONE
}

function App() {

    const [user, setUser] = useState(checkAuthed())
    const [message, setMessage] = useState(alertData)

    const logout = () => {
        makeLogout()
        setUser(null)
    }

    const adminRoutes = [
        <Route path={AdminRoutes.HOME} element={<Home />} key="1" />,
        <Route path={AdminRoutes.PROFILE} element={<Profile callback={logout} type={PROFILE_TYPES.VIEW} />} key="2" />,
        <Route path={AdminRoutes.QUESTIONNAIRES} element={<QuestionnaireList type={QUESTIONARES_TYPES.EDIT} />} key="3" />,
        <Route path={AdminRoutes.SINGLE_QUESTIONNAIRE} element={<QuestionnaireCreator editable />} key="4" />,
        <Route path={AdminRoutes.CREATE_QUESTIONNAIRE} element={<QuestionnaireCreator />} key="5" />,
        <Route path={AdminRoutes.USERS} element={<CustomerList />} key="6" />,
        <Route path={AdminRoutes.SINGLE_USER} element={<Profile type={PROFILE_TYPES.EDIT} />} key="7" />,
        <Route path={AdminRoutes.STATISITC} element={<Statistic />} key="8" />,
        <Route path={AdminRoutes.CREATE_USER} element={<Profile type={PROFILE_TYPES.CREATOR} />} key="9" />,
        <Route path={AdminRoutes.RECORDS} element={<RecordList adminMode />} key="10" />,
        <Route path={AdminRoutes.SINGLE_RECORD} element={<QuestionnaireFiller editable />} key="11" />,
        <Route path={AdminRoutes.TEST_PLACES} element={<TestPlacesList type={TEST_PLACE.EDIT} />} key="12" />,
        <Route path={AdminRoutes.CREATE_PLACE} element={<PlaceFiller />} key="13" />,
        <Route path={AdminRoutes.SINGLE_PLACE} element={<PlaceFiller />} key="14" />,
        <Route path={AdminRoutes.TEST_SUPPLIES} element={<TestSupplyList type={TEST_SUPPLY.EDIT} />} key="15" />,
        <Route path={AdminRoutes.CREATE_SUPPLY} element={<SupplyFiller type={TEST_SUPPLY.APPROVABLE} />} key="16" />,
        <Route path={AdminRoutes.SINGLE_SUPPLY} element={<SupplyFiller type={TEST_SUPPLY.APPROVABLE} />} key="17" />,
        <Route path={AdminRoutes.MANUAL_RECORD} element={<ManualRecord />} key="18" />,
        <Route path='*' element={<Navigate to={AdminRoutes.HOME} />} key="19" />,
    ]

    const userRoutes = [
        <Route path={UserRoutes.HOME} element={<Home />} key="1" />,
        <Route path={UserRoutes.PROFILE} element={<Profile callback={logout} type={PROFILE_TYPES.VIEW} />} key="2" />,
        <Route path={UserRoutes.QUESTIONNAIRES} element={<FilledList />} key="3" />,
        <Route path={UserRoutes.STATISITC} element={<Statistic limited />} key="4" />,
        <Route path={UserRoutes.RECORDS} element={<RecordList />} key="5" />,
        <Route path={UserRoutes.CREATE_RECORD} element={<QuestionnaireFiller />} key="6" />,
        <Route path={UserRoutes.SINGLE_RECORD} element={<QuestionnaireFiller editable />} key="7" />,
        <Route path={UserRoutes.CREATE_SUPPLY} element={<SupplyFiller />} key="8" />,
        <Route path='*' element={<Navigate to={AdminRoutes.HOME} />} key="9" />,
    ]

    const assistantRoutes = [
        <Route path={AssistentRoutes.HOME} element={<Home />} key="1" />,
        <Route path={AssistentRoutes.PROFILE} element={<Profile callback={logout} type={PROFILE_TYPES.VIEW} />} key="2" />,
        <Route path={AssistentRoutes.RECORDS} element={<RecordList />} key="3" />,
        <Route path={AssistentRoutes.SINGLE_RECORD} element={<QuestionnaireFiller editable />} key="4" />,
        <Route path={AssistentRoutes.MANUAL_RECORD} element={<ManualRecord />} key="5" />,
        <Route path='*' element={<Navigate to={AssistentRoutes.HOME} />} key="6" />,
    ]


    const psychologerRoutes = [
        <Route path={PsychologerRoutes.HOME} element={<Home />} key="1" />,
        <Route path={PsychologerRoutes.PROFILE} element={<Profile callback={logout} type={PROFILE_TYPES.VIEW} />} key="2" />,
        <Route path={PsychologerRoutes.QUESTIONNAIRES} element={<FilledList />} key="3" />,
        <Route path={PsychologerRoutes.STATISITC} element={<Statistic limited />} key="4" />,
        <Route path={PsychologerRoutes.RECORDS} element={<RecordList />} key="5" />,
        <Route path={PsychologerRoutes.CREATE_RECORD} element={<QuestionnaireFiller />} key="6" />,
        <Route path={PsychologerRoutes.SINGLE_RECORD} element={<QuestionnaireFiller editable />} key="7" />,
        <Route path={PsychologerRoutes.APPEALS} element={<AppealList />} key="8" />,
        <Route path={PsychologerRoutes.CREATE_SESSION} element={<AppealFiller type={APPEAL_TYPES.CREATOR} />} key="9" />,
        <Route path={PsychologerRoutes.SESSIONS} element={<SessionList />} key="10" />,
        <Route path={PsychologerRoutes.EDIT_SESSION} element={<AppealFiller type={APPEAL_TYPES.EDIT} />} key="11" />,
        <Route path='*' element={<Navigate to={PsychologerRoutes.HOME} />} key="12" />,
    ]

    const authRoutes = [
        <Route path={AuthRoutes.LOGIN} element={<Auth callback={setUser}/>} key="1" />,
        <Route path='*' element={<Navigate to={AuthRoutes.LOGIN} />} key="2" />,
    ]

    const providerRoutes = [
        <Route path={ProviderRoutes.HOME} element={<Home />} key="1" />,
        <Route path={ProviderRoutes.SUPPLIES} element={<TestSupplyList />} key="2" />,
        <Route path={ProviderRoutes.SUPPLY_RECORD} element={<SupplyFiller type={TEST_SUPPLY.SUPPLIABLE} />} key="3" />,
        <Route path={ProviderRoutes.PROFILE} element={<Profile callback={logout} type={PROFILE_TYPES.VIEW} />} key="4" />,
        <Route path='*' element={<Navigate to={PsychologerRoutes.HOME} />} key="5" />,
    ]

    const routesFactory = {
        'admin': adminRoutes,
        'doctor': userRoutes,
        'psychologer': psychologerRoutes,
        'test_provider': providerRoutes,
        'assistant': assistantRoutes
    }

    const generateRoutes = () => {
        if(!user) {
            return authRoutes
        }

        return routesFactory[user.role]
    }
    return (
        <AuthContext.Provider value={user}>
            <AlertContext.Provider value={[message, setMessage]}>
                <Header />
                {
                    message.severity !== SEVERITY_TYPE.NONE && <Alert />
                }
                <Routes>
                    {generateRoutes()}
                </Routes>
                <Footer />
            </AlertContext.Provider>
        </AuthContext.Provider>
    );
}

export default App;
