import editting from '../../assets/images/editing.svg';
import deletting from '../../assets/images/delete.svg';
import './testSupplyList.styles.css';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getQuestionares } from '../../services/questionareService';
import { ITEMS_PER_PAGE, SUPPLY_STATUSES, TEST_SUPPLY } from '../../constants/dataConstants';
import { useNavigate } from 'react-router-dom';
import { AdminRoutes } from '../../constants/pages';
import Loading from '../../components/loading/loading.component';
import { AlertContext } from '../../App';
import { SEVERITY_TYPE } from '../../constants/messages';
import React from 'react';
import { deleteSupply, getSupplies } from '../../services/testService';
import Table from '../../components/table/table.component';
import filterIcon from '../../assets/images/filter.svg';

function TestSupplyList({ type }) {
    const [supplies, setSupplies] = useState({
        supplies: [],
        totalPages: 0
    })
    const [loading, setLoading] = useState(2)
    const [currentPage, setCurrentPage] = useState(1)
    const [status, setStatus] = useState(type === TEST_SUPPLY.EDIT ? 'created' : 'approved')
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()

    useEffect(() => {
        getSupplies(1, ITEMS_PER_PAGE, status)
            .then(response => {
                setSupplies(response)
                setCurrentPage(1)
            })
            .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
            .finally(() => setLoading(0))
            
    }, [setSupplies, setLoading, setMessage, status])

    const deleteHandler = async (id) => {
        const confirmed = window.confirm('Впевнені, що бажаєте видалити?')
        if(confirmed) {
            setLoading(1)
            try {
                await deleteSupply(id)
                const response = await getSupplies(1, ITEMS_PER_PAGE, status)
                setSupplies(response)
                setCurrentPage(1)
            } catch (e) {
                setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
            } finally {
                setLoading(0)
            }
        }
    }

    const statusHandler = useCallback((event) => {
        setStatus(event.target.value)
    }, [setStatus])

    const getStatuses = () => {
        if (type !== TEST_SUPPLY.EDIT) {
            const keys = Object.keys(SUPPLY_STATUSES).filter(key => !SUPPLY_STATUSES[key].editOnly)

            return keys.map((key) => ({value: key, label: SUPPLY_STATUSES[key].text}))
        }
        console.log(Object.keys(SUPPLY_STATUSES));
        return  Object.keys(SUPPLY_STATUSES).map((key) => ({value: key, label: SUPPLY_STATUSES[key].text}))
    }

    const renderStatuses = () => {
        return getStatuses().map(status =>
            <option key={status.value} value={status.value}>
                {status.label}
            </option>
        )
    }

    const loadMoreHandler = async () => {
        setLoading(1) 
        try {
            const response = await getQuestionares(ITEMS_PER_PAGE, currentPage + 1, status)
            setSupplies({
                questionares: [...supplies.supplies, ...response.supplies],
                totalPages: response.totalPages
            })
            setCurrentPage(currentPage + 1)
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    }

    const addHandler = () => {
        navigate(AdminRoutes.CREATE_SUPPLY)
    }

    const titles = ['Пошта', 'Отримувач', '']

    const supplyPattern = (supply) => 
        <>
            <p>
                {supply.postAddress || supply.placePostAddress}
            </p>
            <p>
                {supply.receiver || supply.placeReciever}
            </p>
            <div>
                <button className='icon' onClick={() => navigate(`./${supply.id}`)}>
                    <img src={editting} alt="" />
                </button>
                <button className='icon' onClick={() => deleteHandler(supply.id)}>
                    <img src={deletting} alt="" />
                </button>
            </div>
        </>

    const isAllItems = currentPage >= supplies.totalPages

    const renderContent = () =>
        <section className='questionnaireList'>
            <div className="container">
                <h2 className="questionnaireList__title">
                   Запити на постачання тестів
                </h2>
                {type === TEST_SUPPLY.EDIT ?
                    <button
                        className='questionnaireList__adder button'
                        onClick={addHandler}
                    >
                        Додати запит на постачання
                    </button>
                    : null
                }
                <div className="recordList__controlls">
                    <div className='recordList__searcher'>
                        <img src={filterIcon} alt="" className='recordList__icon' />
                        <select
                            className='recordList__input'
                            value={status}
                            onChange={statusHandler}
                        >
                            {renderStatuses()}
                        </select>
                    </div>
                </div>
                <Table
                    data={supplies.supplies}
                    emptyLabel="Немає місць тестування"
                    pattern={supplyPattern}
                    titles={titles}
                />
                {!isAllItems ?
                    <button
                        className='questionnaireList__button button'
                        onClick={loadMoreHandler}
                    >
                        Більше
                    </button> :
                    null
                }
            </div>
        </section>

    return loading === 2 ? <Loading />
        : loading === 1 ?
            <>
                {renderContent()}
                <Loading />
            </>:
            renderContent();
}

export default TestSupplyList;