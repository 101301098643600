import EditableItem from '../../components/editableItem/editableItem.component'
import editting from '../../assets/images/editing.svg'
import search from '../../assets/images/search.svg'
import filterIcon from '../../assets/images/filter.svg'
import './recordList.styles.css'
import deletting from '../../assets/images/delete.svg';
import { useCallback, useContext, useEffect, useState } from 'react'
import { deleteRecord, getRecords, getRecordsWithType } from '../../services/recordsService'
import { ITEMS_PER_PAGE, STATUS_SEPARATOR } from '../../constants/dataConstants'
import { useNavigate } from 'react-router-dom'
import { JSONdateToFormat } from '../../services/questionareService'
import { debounce } from '../../util'
import Loading from '../../components/loading/loading.component'
import { AlertContext, AuthContext } from '../../App'
import { DELETE_MESSAGE, SEVERITY_TYPE } from '../../constants/messages'
import React from 'react'
import { getPlaceById } from '../../services/testService'
import { UserRoutes } from '../../constants/pages'

function RecordList({ adminMode }) {
    const [records, setRecords] = useState({
        records: [],
        totalPages: 0
    })
    const [currentPage, setCurrentPage] = useState(1)
    const [role, setRole] = useState('doctor')
    const [query, setQuery] = useState('')
    const [place, setPlace] = useState('')
    const [loading, setLoading] = useState(2)
    const [filter, setFilter] = useState(false)
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()
    const userData = useContext(AuthContext)

    const sender = useCallback(debounce(async (query) => {
        setLoading(1)
        try {
            const response = await getRecordsWithType(ITEMS_PER_PAGE, 0, query, filter, role)
            setRecords(response)
            setCurrentPage(1)
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    }, 500), [setRecords, setCurrentPage, filter])

    const searchHandler = (e) => {
        const value = e.target.value
        setQuery(value)
        sender(value)
    }

    const toggleRole = () => {
        if(role === 'doctor') {
            setRole('psycho')
        } else {
            setRole('doctor')
        }
    }

    const deleteHadler = async (id) => {
        const confirmed = window.confirm(DELETE_MESSAGE)
        if(confirmed) {
            setLoading(1)
            try {
                await deleteRecord(id)
                const response = await getRecordsWithType(ITEMS_PER_PAGE, 0, query, filter, role)
                setRecords(response)
                setCurrentPage(1)
            } catch(e) {
                setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
            } finally {
                setLoading(0)
            }
        }
    }

    const filterHandler = async (e) => {
        const value = e.target.value
        setFilter(value)
    }

    const createRequestHandler = () => navigate(UserRoutes.CREATE_SUPPLY)

    const renderTestStatus = () => <div>
        <h3 className='test-count'>Кількість тестів ВІЛ у вашому місці тестування: {place.HIVCount}</h3>
        <h3 className='test-count'>Кількість мульти-тестів у вашому місці тестування: {place.multiCount}</h3>
        <button
            className='test-adder button'
            type='button'
            onClick={createRequestHandler}
        >
            Запросити тести
        </button>
    </div>

    useEffect(() => {
        if (!userData.testingPlace) return;
        getPlaceById(userData.testingPlace)
            .then(place => setPlace(place))
            .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))

    },[userData, setPlace])

    useEffect(() => {
        setLoading(1)
        getRecords(ITEMS_PER_PAGE, 0, filter, role)
            .then(response => {
                setRecords(response)
                setCurrentPage(1)
            })
            .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
            .finally(() => setLoading(0))
    }, [setRecords, setMessage, role, filter])

    const loadMoreHandler = async() => {
        setLoading(1)
        const offset = currentPage * ITEMS_PER_PAGE
        try {
            const response = await getRecordsWithType(ITEMS_PER_PAGE, offset, query, filter, role)
            setRecords({
                records: [...records.records, ...response.records],
                totalPages: response.totalPages
            })
            setCurrentPage(currentPage + 1)
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    }

    const getControlls = () => {
        return adminMode ? [editting, deletting] : [editting]
    }

    const getCallbacks = (id) => {
        return adminMode ? [navigate.bind(null, `./${id}`), deleteHadler.bind(null, id)] : [navigate.bind(null, `./${id}`)]
    }


    const renderRecords = () => {
        return records.records.map((item, index) =>
            <li key={item.id}>
                <EditableItem
                    controlls={getControlls()}
                    callbacks={getCallbacks(item.id)}
                    number={index + 1}>
                    <div className="record__content">
                        <p className="record__field">
                            {item.name}
                        </p>
                        <p className="record__field">
                            {item.pseudonim}
                        </p>
                        <p className="record__field">
                            Cтатус: {item.results.map(result=>result.name).join(STATUS_SEPARATOR)}
                        </p>
                        <p className="record__field">
                            {item.individualBirth}
                        </p>
                        <p className="record__field">
                            {JSONdateToFormat(item.date)}
                        </p>
                    </div>
                </EditableItem>
            </li>
        )
    }

    const isAllItems = currentPage >= records.totalPages

    const renderContent = () =>
        <section className='recordList'>

            {adminMode ?
                <div className="toggler-container">
                    <div className="toggler">
                        <button onClick={toggleRole} className={role === 'doctor' ? 'active' : ''}>Лікар</button>
                        <button onClick={toggleRole} className={role === 'psycho' ? 'active' : ''}>Психолог</button>
                    </div>
                </div>
                : renderTestStatus()
            }
            
            <div className="container">
                <h2 className="recordList__title">Анкети</h2>
                <div className="recordList__controlls">
                    <div className='recordList__searcher'>
                        <img src={search} alt="" className='recordList__icon' />
                        <input
                            type='text'
                            className='recordList__input'
                            placeholder='Пошук'
                            value={query}
                            onChange={searchHandler}
                        />
                    </div>
                    <div className='recordList__searcher'>
                        <img src={filterIcon} alt="" className='recordList__icon' />
                        <select
                            className='recordList__input'
                            value={filter}
                            onChange={filterHandler}
                        >
                            <option value={true}>З результатом</option>
                            <option value={false}>Без результату</option>
                        </select>
                    </div>
                </div>

                <ul className='recordList__list'>
                    {records.records.length > 0 ? renderRecords() : 'Немає записів'}
                </ul>
                {
                    !isAllItems ?
                        <button
                            className='record__button button'
                            onClick={loadMoreHandler}
                        >
                            Більше
                        </button>:
                        null
                }
            </div>
        </section>

    return loading === 2 ? <Loading />:
        loading === 1 ?
            <>
                {renderContent()}
                <Loading />
            </>:
            renderContent();
}

export default RecordList;