export const ADMIN_INSTRUCTURES = [
    {
        data: 'Інструкція адміністратора по роботі з веб-ресурсом "Скринінгові анкетування"'
    },
    {
        title: 'Авторизація',
        data: 'Для того, щоб авторизуватися у системі, необхідно у вікні авторизації ввести логін і пароль, які були вказані при реєстрації, та натиснути клавішу "Увійти". Якщо при авторизації виникли помилки, відобразиться повідомлення про відповідну помилку.'
    },
    {
        title: 'Анкети',
        data: 'Сторінка “Анкети” містить список всіх існуючих анкет. Адміністратор може видалити, редагувати анкету, або додати нову. При видаленні потрібно нажати клавішу з зображенням видалення, при цьому анкета видалиться. Після нажаття клавіші з зображенням редагування відбудеться перехід на сторінку, де знаходяться дані анкети, які можна редагувати, а також додавати нові питання. Для створення нової анкети нажати на клавішу “Додати анкету”. Відбудеться перехід на сторінку, на якій потрібно ввести дані нової анкети та нажати на клавішу “Зберегти”.'
    },
    {
        title: 'Користувачі',
        data: 'На сторінці «Користувачі» відображено перелік лікарів системи. Для видалення користувача із системи потрібно нажати клавішу з зображенням «Видалити». Міститься функція редагування даних про лікаря. Щоб деактивувати профіль лікаря необхідно нажати клавішу з зображенням замка. Заблоковані профілі будуть підсвічуватись червоним кольором. Для розблокування повторно нажати на замок.'
    },
    {
        title: 'Статистика',
        data: 'В розділі «Статистика» обирається тип анкети, за якою буде відображатись статистика, після цього обирається місто та період для статистики. Нажати на клавішу «Обрати». В результаті статистики містяться дані про кількість опитаних та про кількість хворих. Для завантаження статистики у форматі “csv” потрібно нажати клавішу “Завантажити”.'
    },
    {
        title: 'Профіль',
        data: 'Сторінка «Профіль» містить інформацію про особисті дані адміністратора. Розміщена клавіша «Вийти» для виходу з профіля адміністратора.'
    }
]

export const DOCTOR_INSTRUCTURES = [
    {
        data: 'Інструкція користувача по роботі з веб-ресурсом "Скринінгові анкетування"'
    },
    {
        title: 'Авторизація',
        data: 'Для того, щоб авторизуватися у системі, необхідно у вікні авторизації ввести логін і пароль, які були вказані адміністратором при реєстрації, та натиснути клавішу "Увійти". Якщо при авторизації виникли помилки, відобразиться повідомлення про відповідну помилку.'
    },
    {
        title: 'Анкети',
        data: 'На сторінці «Анкети» містяться заповнені анкети пацієнтів. Доступна функція редагування заповненої анкети.'
    },
    {
        title: 'Заповнити анкету',
        data: 'Щоб заповнити анкету, потрібно обрати зі списку доступну та натиснути на клавішу «+». Після цього відбувається перехід на сторінку заповнення. Потрібно ввести псевдонім пацієнта, рік народження та місто, в якому проводиться тестування. При заповненні не всіх полів або неправильних даних виведеться помилка про це. Нажати на клавішу “Далі”. Потрібно дати відповіді на задані питання, після яких з’являється інформація про введені відповіді. Перевірити, при потребі змінити вибір, нажати клавішу “Зберегти”. Якщо заповнені не всі відповіді виводиться повідомлення про це.'
    },
    {
        title: 'Статистика',
        data: 'В розділі «Статистика» обирається тип анкети, за якою буде відображатись статистика, після цього обирається період для статистики. Нажати на клавішу «Обрати». Варто зазначити, що лікар має доступ до інформації про статистику лише по місту, в якому знаходиться. В результаті статистики містяться дані про кількість опитаних та про кількість хворих. Для завантаження статистики у форматі “csv” потрібно нажати клавішу “Завантажити”.'
    },
    {
        title: 'Профіль',
        data: 'Сторінка «Профіль» містить інформацію про особисті дані лікаря. Розміщена клавіша «Вийти» для виходу з профіля лікаря.'
    }
]