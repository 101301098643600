export const UserRoutes = {
    HOME: '/',
    RECORDS: '/records',
    SINGLE_RECORD: '/records/:id',
    CREATE_RECORD: '/questionnaires/:id',
    QUESTIONNAIRES: '/questionnaires',
    PROFILE: '/profile',
    STATISITC: '/stats',
    CREATE_SUPPLY: '/supplies/create'
}

export const AssistentRoutes = {
    HOME: '/',
    RECORDS: '/records',
    SINGLE_RECORD: '/records/:id',
    MANUAL_RECORD: '/manual',
    PROFILE: '/profile',
}

export const ProviderRoutes = {
    HOME: '/',
    SUPPLIES: '/supplies',
    SUPPLY_RECORD: '/supplies/:id',
    PROFILE: '/profile'
}

export const PsychologerRoutes = {
    HOME: '/',
    RECORDS: '/records',
    SINGLE_RECORD: '/records/:id',
    CREATE_RECORD: '/questionnaires/:id',
    QUESTIONNAIRES: '/questionnaires',
    PROFILE: '/profile',
    APPEALS: '/appeals',
    CREATE_SESSION: '/appeals/:id',
    EDIT_SESSION: '/sessions/:id',
    SESSIONS: '/sessions',
    STATISITC: '/stats'
}

export const AdminRoutes = {
    HOME: '/',
    QUESTIONNAIRES: '/questionnaires',
    SINGLE_QUESTIONNAIRE: '/questionnaires/:id',
    CREATE_QUESTIONNAIRE: '/questionnaires/create',
    RECORDS: '/records',
    SINGLE_RECORD: '/records/:id',
    PROFILE: '/profile',
    USERS: '/users',
    SINGLE_USER: '/users/:id',
    CREATE_USER: '/users/create',
    STATISITC: '/stats',
    TEST_PLACES: '/places',
    CREATE_PLACE: '/places/create',
    SINGLE_PLACE: '/places/:id',
    TEST_SUPPLIES: '/supplies',
    CREATE_SUPPLY: '/supplies/create',
    SINGLE_SUPPLY: '/supplies/:id',
    MANUAL_RECORD: '/manual',
}

export const AuthRoutes = {
    LOGIN: '/'
}
