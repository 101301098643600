import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { AlertContext } from '../../App';
import { SEVERITY_TYPE } from '../../constants/messages';
import { getSessionStatistics } from '../../services/appealService';
import './psychologistStatistic.styles.css';
import React from 'react';

function PsychologistStatistic({dateStart, dateEnd}) {

    const [statistic, setStatistic] = useState({
        incoming: 0,
        progressing: 0,
        completed: 0,
        total: 0
    })

    const [, setMessage] = useContext(AlertContext)

    useEffect(() => {
        getSessionStatistics(dateStart, dateEnd)
            .then(data => setStatistic(data))
            .catch(e => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
    }, [])

    return (
        <div className='psychologist_statistic'>
            <ul>
                <li>
                    <p className='stat_label'>Неопрацьованих звернень:</p>
                    <p>{ statistic.incoming }</p>
                </li>
                <li>
                    <p className='stat_label'>Активних клієнтів:</p>
                    <p>{ statistic.progressing }</p>
                </li>
                <li>
                    <p className='stat_label'>Опрацьованих звернень:</p>
                    <p>{ statistic.completedClients }</p>
                </li>
                <li>
                    <p className='stat_label'>Всього звернень:</p>
                    <p>{ statistic.totalClients }</p>
                </li>
                <li>
                    <p className='stat_label'>Всього сеансів:</p>
                    <p>{ statistic.total }</p>
                </li>
            </ul>
        </div>
    );
}

export default PsychologistStatistic;