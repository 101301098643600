import { useContext, useEffect, useState } from 'react';
import { AlertContext, AuthContext } from '../../App';
import Loading from '../../components/loading/loading.component';
import PsychologistStatistic from '../../components/psychologistStatistic/psychologistStatistic.component';
import { INVALID_DATE, SEVERITY_TYPE } from '../../constants/messages';
import { getCities } from '../../services/recordsService';
import { downloadStats, downloaQuantitydStats } from '../../services/statsService';
import './statistic.styles.css';
import React from 'react';

const initalFilter = {
    startDate: '',
    endDate: '',
    city: ''
}

function Statistic({ limited }) {

    const [cities, setCities] = useState([])
    const [filter, setFilter] = useState(initalFilter)
    const [loading, setLoading] = useState(2)
    const [, setMessage] = useContext(AlertContext)
    const auth = useContext(AuthContext)
    const [showStatistic, setShowStatistic] = useState(false)

    const changeFilter = (field, value) => {
        setFilter({
            ...filter,
            [field]: value
        })
        setShowStatistic(false)
        if(field === 'endDate' && filter.startDate && value < filter.startDate) {
            setMessage({severity: SEVERITY_TYPE.ERROR, message: INVALID_DATE})
        }
        if(field === 'startDate' && filter.endDate && value < filter.startDate) {
            setMessage({severity: SEVERITY_TYPE.ERROR, message: INVALID_DATE})
        }
    }

    useEffect(() => {
        if(!limited && auth.role === 'admin') {
            getCities()
                .then(data => {
                    setCities(data)
                })
                .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
                .finally(()=> setLoading(0))
        }

        if(auth.role === 'psychologer' || limited) {
            changeFilter('city', auth.cityId)
            setLoading(0)
        }
    }, [])

    const renderCities = () => {
        return cities.map(city =>
            <option key={city.id} value={city.id}>
                {city.name}
            </option>
        )
    }

    const checkDates = () => {
        if(filter.endDate && filter.startDate && filter.endDate > filter.startDate) {
            return true
        }
        return false
    }

    const getDownloadUrl = () => {
        return downloadStats(filter.city, filter.startDate, filter.endDate)
    }

    const getOverviewDownloadUrl = () => {
        return downloaQuantitydStats(filter.city, filter.startDate, filter.endDate)
    }

    const showStatisticHandler = () => {
        setShowStatistic(true)
    }

    const renderContent = () =>
        <section className='statistic'>
            <div className='container'>
                <h2 className='statistic__title'>Статистика</h2>
                <div className='filter'>
                    <h2 className="filter__title">
                        Оберіть фільтр:
                    </h2>
                    <form className='filter__form'>
                        {!limited ?
                            <label className='filter__label'>
                                Місто
                                <select
                                    className='filter__input'
                                    value={filter.city}
                                    onChange={(e) => changeFilter('city', e.target.value)}
                                >
                                    <option value=''>Усі міста</option>
                                    {renderCities()}
                                </select>
                            </label>: null
                        }
                        <label className='filter__label'>
                            Період
                            <input
                                type="date"
                                className='filter__input--both'
                                value={filter.startDate}
                                onChange={(e) => changeFilter('startDate', e.target.value)}
                            />
                            -
                            <input
                                type="date"
                                className='filter__input'
                                value={filter.endDate}
                                onChange={(e) => changeFilter('endDate', e.target.value)}
                            />
                        </label>
                        {checkDates() ?
                            <div className="filter__controlls">
                                {!limited ?
                                    <a
                                        className='filter__button button'
                                        type='button'
                                        href={getDownloadUrl()}
                                    >
                                        Детальна статистика
                                    </a>:
                                    null
                                }
                                {
                                    auth.role === 'psychologer' ? 
                                        <button
                                            className='filter__button button'
                                            type='button'
                                            onClick={showStatisticHandler}
                                        >
                                            Отримати статистику
                                        </button>:
                                        <a
                                            className='filter__button button'
                                            type='button'
                                            href={getOverviewDownloadUrl()}
                                        >
                                            Загальна статистика
                                        </a>
                                }
                            </div>:
                            null
                        }
                    </form>
                </div>
                {
                    auth.role === 'psychologer' && showStatistic ?
                        <PsychologistStatistic  dateEnd={filter.endDate} dateStart={filter.startDate} /> :
                        null
                }
            </div>
        </section>

    return loading === 2 ? <Loading />:
        loading === 1 ?
            <>
                {renderContent()}
                <Loading />
            </>:
            renderContent();
}

export default Statistic;