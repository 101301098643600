import EditableItem from '../../components/editableItem/editableItem.component';
import editting from '../../assets/images/editing.svg';
import plus from '../../assets/images/plus.svg'
import './questionnaireList.styles.css';
import { useContext, useEffect, useState } from 'react';
import { getQuestionares, JSONdateToFormat } from '../../services/questionareService';
import { ITEMS_PER_PAGE, QUESTIONARES_TYPES } from '../../constants/dataConstants';
import { useNavigate } from 'react-router-dom';
import { AdminRoutes } from '../../constants/pages';
import Loading from '../../components/loading/loading.component';
import { AlertContext } from '../../App';
import { SEVERITY_TYPE } from '../../constants/messages';
import React from 'react';

function QuestionnaireList({ type }) {
    const [questionares, setQuestionnares] = useState({
        questionares: [],
        totalPages: 0
    })
    const [loading, setLoading] = useState(2)
    const [currentPage, setCurrentPage] = useState(1)
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()

    useEffect(() => {
        getQuestionares(ITEMS_PER_PAGE, 0)
            .then(response => {
                setQuestionnares(response)
            })
            .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
            .finally(() => setLoading(0))
    }, [setQuestionnares, setLoading, setMessage])

    // const deleteHadler = async (id) => {
    //     const confirmed = window.confirm('Впевнені, що бажаєте видалити?')
    //     if(confirmed) {
    //         setLoading(1)
    //         try {
    //             await deleteQuestionare(id)
    //             const response = await getQuestionares(ITEMS_PER_PAGE, 0)
    //             setQuestionnares(response)
    //             setCurrentPage(1)
    //         } catch (e) {
    //             setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
    //         } finally {
    //             setLoading(0)
    //         }
    //     }
    // }

    const loadMoreHandler = async () => {
        setLoading(1)
        const offset = currentPage * ITEMS_PER_PAGE
        try {
            const response = await getQuestionares(ITEMS_PER_PAGE, offset)
            setQuestionnares({
                questionares: [...questionares.questionares, ...response.questionares],
                totalPages: response.totalPages
            })
            setCurrentPage(currentPage + 1)
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(0)
        }
    }

    const addHandler = () => {
        navigate(AdminRoutes.CREATE_QUESTIONNAIRE)
    }

    const renderQuestionares = () => {
        return questionares.questionares.map((item, index) =>
            <li key={item.id}>
                <EditableItem
                    controlls={[editting]}
                    callbacks={[navigate.bind(null, `./${item.id}`)]}
                    number={index + 1}
                >
                    <div className="questionnaire__content" to={`./${item.id}`}>
                        <p className="questionnaire__field">
                            {item.name}
                        </p>
                        <p className="questionnaire__field">
                            {JSONdateToFormat(item.date)}
                        </p>
                    </div>
                </EditableItem>
            </li>
        )
    }

    const renderShortQuestionares = () => {
        return questionares.questionares.map((item, index) =>
            <li key={item.id}>
                <EditableItem
                    controlls={[plus]}
                    callbacks={[navigate.bind(null, `./${item.id}`)]}
                    number={index + 1}
                >
                    <div className="questionnaire__content" to={`./${item.id}`}>
                        <p className="questionnaire__field">
                            {item.name}
                        </p>
                    </div>
                </EditableItem>
            </li>
        )
    }

    const isAllItems = currentPage >= questionares.totalPages

    const renderContent = () =>
        <section className='questionnaireList'>
            <div className="container">
                <h2 className="questionnaireList__title">
                    {type === QUESTIONARES_TYPES.SHORT ? 'Cтатистика' : 'Анкети'}
                </h2>
                {type === QUESTIONARES_TYPES.EDIT ?
                    <button
                        className='questionnaireList__adder button'
                        onClick={addHandler}
                    >
                        Додати анкету
                    </button>
                    : null
                }
                <ul className='questionnaireList__list'>
                    {
                        type !== QUESTIONARES_TYPES.SHORT && questionares.questionares.length > 0 ?
                            renderQuestionares() :
                            questionares.questionares.length > 0  ?
                                renderShortQuestionares() :
                                'Немає анкет'
                    }
                </ul>
                {!isAllItems ?
                    <button
                        className='questionnaireList__button button'
                        onClick={loadMoreHandler}
                    >
                        Більше
                    </button> :
                    null
                }
            </div>
        </section>

    return loading === 2 ? <Loading />
        : loading === 1 ?
            <>
                {renderContent()}
                <Loading />
            </>:
            renderContent();
}

export default QuestionnaireList;