import { useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';

import { getCurrentPathId } from '../../services/routerService';


import {  INVALID_PLACE_DATA, SEVERITY_TYPE, SUCCESS_PLACE_UPDATED_MESSAGE } from '../../constants/messages';
import './placeFiller.styles.css';
import { AlertContext } from '../../App';
import Loading from '../../components/loading/loading.component';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { createPlace, getPlaceById, updatePlace } from '../../services/testService';
import { getCities } from '../../services/recordsService';
import MaskedInput from 'react-text-mask';
import { PHONE_MASK, TIME_MASK } from '../../constants/dataConstants';

function PlaceFiller() {
    const [place, setPlace] = useState()
    const [cities, setCites] = useState()
    const [loading, setLoading] = useState(true)
    const [touched, setTouched] = useState(false)
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()
    
    const changeAddress = event => {
        setTouched(true)
        setPlace({
            ...place,
            address: event.target.value
        })
    }

    const changeHIVCount = event => {
        setTouched(true)
        setPlace({
            ...place,
            HIVCount: +event.target.value
        })
    }

    const changeMultiCount = event => {
        setTouched(true)
        setPlace({
            ...place,
            multiCount: +event.target.value
        })
    }

    const changeReciever = event => {
        setTouched(true)
        setPlace({
            ...place,
            receiver: event.target.value
        })
    }

    const changeEndTime = event => {
        setTouched(true)
        setPlace({
            ...place,
            endTime: event.target.value
        })
    }

    const changeStartTime = event => {
        setTouched(true)
        setPlace({
            ...place,
            startTime: event.target.value
        })
    }

    const changePhone = event => {
        setTouched(true)
        setPlace({
            ...place,
            phone: event.target.value
        })
    }

    const changePostAddress = event => {
        setTouched(true)
        setPlace({
            ...place,
            postAddress: event.target.value
        })
    }

    const changeCity = event => {
        setTouched(true)
        setPlace({
            ...place,
            city: event.target.value
        })
    }

    const addHandler = async () => {
        try {
            setLoading(true)
            await createPlace(
                {   
                    startHour: place.startTime,
                    endHour: place.endTime,
                    HIVCount: place.HIVCount,
                    city: place.city,
                    multiCount: place.multiCount,
                    postAddress: place.postAddress,
                    address: place.address,
                    receiver: place.receiver,
                    phone: place.phone
                }
            )
    
            navigate('./../')
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(false)
        }
    }

    const updateHandler = async () => {
        try {
            setLoading(true)
            await updatePlace(
                place.id,
                {   
                    startHour: place.startTime,
                    endHour: place.endTime,
                    HIVCount: place.HIVCount,
                    city: place.city,
                    multiCount: place.multiCount,
                    postAddress: place.postAddress,
                    address: place.address,
                    reciever: place.receiver,
                    phone: place.phone
                }
            )
            setTouched(false)
            setMessage({message: SUCCESS_PLACE_UPDATED_MESSAGE, severity: SEVERITY_TYPE.SUCCESS})
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(false)
        }
    }

    const calncelHandler = () => {
        navigate('./../')
    }

    const dataFetcher = useCallback(() => {
        const id = getCurrentPathId()

        setLoading(true)
        let cityPromise = getCities()

        if (id === 'create') {
            cityPromise = cityPromise
                .then(res =>  {
                    setCites(res)
                    setPlace({
                        startTime: '',
                        endTime: '',
                        HIVCount: 0,
                        city: res[0].id,
                        multiCount: 0,
                        postAddress: '',
                        address: '',
                        receiver: '',
                        phone: ''
                    })
                })
        } else {
            cityPromise = cityPromise
                .then(res => {
                    setCites(res)
                    return getPlaceById(id)
                })
                .then(res => setPlace({
                    startTime:  res.startHour,
                    endTime: res.endHour,
                    id: res.id,
                    HIVCount: res.HIVCount,
                    city: res.city,
                    multiCount:  res.multiCount,
                    postAddress:  res.postAddress,
                    address: res.address,
                    receiver: res.reciever,
                    phone: res.phone
                }))
        }
       
        cityPromise
            .catch((e) => {
                setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})}
            )
            .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        dataFetcher()
    }, [])

    const renderCities = () =>
        cities.map(city =>
            <option value={city.id} key={city.id}>
                {city.name}
            </option>)

    const validateData = () => {
        const filled = !!place.startTime
            && !!place.endTime
            && !!place.city
            && !!place.postAddress
            && !!place.address
            && !!place.phone
            && !!place.receiver
        
        if (!filled) return false

        const [startHours, startMinutes] = place.startTime.split(':');
        const [endHours, endMinutes] = place.endTime.split(':');

        if (+startHours > +endHours) return false

        if(+startHours === +endHours && startMinutes > endMinutes) return false

        return true
    }

    const onSubmit = () => {
        if(!validateData()) {
            setMessage({message: INVALID_PLACE_DATA, severity: SEVERITY_TYPE.ERROR})
            return
        }
    
        const id = getCurrentPathId()
        if(id === 'create') {
            addHandler()
        } else {
            updateHandler()
        }
    }

    const renderContent = () => {
        if(!place) {
            return
        }
        return <section className='place-filler'>
            <div className='container'>
                <div className='appeal__inner'>
                    <h2 className="profile__title">Місце тестування</h2>
                    <ul className="profile__content">
                        <li className="profile__field">
                            <h3 className="profile__name">Місто:</h3>
                            <select
                                className="profile__value"
                                value={place.city}
                                onChange={changeCity}
                            >
                                {renderCities()}
                            </select>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Адреса:</h3>
                            <input
                                className="appeal__value"
                                value={place.address}
                                onChange={changeAddress}
                            ></input>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Телефон:</h3>
                            <MaskedInput
                                mask={PHONE_MASK}
                                className="appeal__value"
                                value={place.phone}
                                onChange={changePhone}
                            ></MaskedInput>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Отримувач:</h3>
                            <input
                                className="appeal__value"
                                value={place.receiver}
                                onChange={changeReciever}
                            >
                            </input>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Графік роботи:</h3>
                            <div className="time-range">
                                <MaskedInput className="appeal__value"
                                    mask={TIME_MASK}
                                    value={place.startTime}
                                    onChange={changeStartTime}
                                ></MaskedInput> -
                                <MaskedInput className="appeal__value"
                                    mask={TIME_MASK}
                                    value={place.endTime}
                                    onChange={changeEndTime}
                                ></MaskedInput>
                            </div>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Адреса пошти:</h3>
                            <input
                                className="appeal__value"
                                value={place.postAddress}
                                onChange={changePostAddress}
                            >
                            </input>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Кількість ВІЛ:</h3>
                            <input
                                className="appeal__value"
                                value={place.HIVCount}
                                onChange={changeHIVCount}
                                type='number'
                            >
                            </input>
                        </li>
                        <li className="profile__field">
                            <h3 className="profile__name">Кількість мулти-тестів:</h3>
                            <input
                                className="appeal__value"
                                value={place.multiCount}
                                onChange={changeMultiCount}
                                type='number'
                            >
                            </input>
                        </li>
                    
                    </ul>
                </div>

                <div className="profile__controlls">
                    <button
                        className="profile__button button"
                        onClick={calncelHandler}
                    >
                        {touched ? 'Скасувати' : 'Назад'}
                    </button>
                    <button
                        className="profile__button button"
                        onClick={onSubmit}
                        disabled={!touched}
                    >
                        Зберегти
                    </button>
                
                </div>

            </div>
        </section>
    }

    return loading ? <Loading /> : renderContent()
}

export default PlaceFiller;