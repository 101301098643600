import './loading.styles.css';
import React from 'react';

function Loading() {

    return (
        <div className='shadow'>
            <div className='loader' />
        </div>
    );
}

export default Loading;