import './questionnaireCreator.styles.css';
import deletting from '../../assets/images/delete.svg'
import { useContext, useEffect, useState } from 'react';
import { getCurrentPathId } from '../../services/routerService';
import { addQuestionare, getAnswerTypes, getQuestionareDetails, JSONdateToInput, updateQuestionare } from '../../services/questionareService';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/loading/loading.component';
import { AlertContext } from '../../App';
import { SEVERITY_TYPE } from '../../constants/messages';
import React from 'react';

function QuestionnaireCreator({ editable }) {

    const [questionare, setQuestionare] = useState({
        type: {
            name: '',
            startDate: '',
            endDate: '',
            role: 'doctor',
            number: 0
        },
        questions: []
    })
    const [loading, setLoading] = useState(true)
    const [answerTypes, setAnswerTypes] = useState([])
    const [touched, setTouched] = useState(false)
    const [, setMessage] = useContext(AlertContext)
    const navigate = useNavigate()

    useEffect(() => {
        let response
        if(editable) {
            const id = getCurrentPathId()
            response = getQuestionareDetails(id)
                .then(response => setQuestionare({
                    ...response,
                    type: {
                        ...response.type,
                        startDate: JSONdateToInput(response.type.startDate),
                        endDate: JSONdateToInput(response.type.endDate),
                    }
                }))
        } else {
            response = new Promise((res) => res())
        }
        response
            .then(() => getAnswerTypes())
            .then(response => setAnswerTypes(response))
            .catch((e) => setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR}))
            .finally(()=>setLoading(false))
    }, [setQuestionare, editable, setMessage])

    const changeName = (event) => {
        setTouched(true)
        setQuestionare({
            ...questionare,
            type: {
                ...questionare.type,
                name: event.target.value,
            },
        })
    }

    const changeStartDate = (event) => {
        setTouched(true)
        setQuestionare({
            ...questionare,
            type: {
                ...questionare.type,
                startDate: event.target.value,
            },
        })
    }

    const changeEndDate = (event) => {
        setTouched(true)
        setQuestionare({
            ...questionare,
            type: {
                ...questionare.type,
                endDate: event.target.value,
            },
        })
    }

    const changeRole = () => {
        setTouched(true)
        if(questionare.type.role === 'psycho') {
            setQuestionare({
                ...questionare,
                type: {
                    ...questionare.type,
                    role: 'doctor'
                },
            })
        } else {
            setQuestionare({
                ...questionare,
                type: {
                    ...questionare.type,
                    role: 'psycho'
                },
            })
        }
    }

    const changeNumber = (event) => {
        setTouched(true)
        setQuestionare({
            ...questionare,
            type: {
                ...questionare.type,
                number: event.target.value,
            },
        })
    }

    const changeQuestion = (name, id) => {
        setTouched(true)
        const newQuestions = questionare.questions.map(question => {
            if(question.questionId === id) {
                return {
                    ...question,
                    question: name
                }
            }
            return question
        })
        setQuestionare({
            ...questionare,
            questions: newQuestions
        })
    }

    const changeAnswerType = (type, id) => {
        setTouched(true)
        const newQuestions = questionare.questions.map(question => {
            if(question.questionId === id) {
                return {
                    ...question,
                    answerType: type
                }
            }
            return question
        })
        setQuestionare({
            ...questionare,
            questions: newQuestions
        })
    }

    const deleteQuestion = (id) => {
        setTouched(true)
        const newQuestions = questionare.questions.filter(question => question.questionId !== id)
        setQuestionare({
            ...questionare,
            questions: newQuestions
        })
    }

    const addQuestion = () => {
        setTouched(true)
        setQuestionare({
            ...questionare,
            questions: [
                ...questionare.questions,
                {
                    question: '',
                    questionId: `i${questionare.questions.length}`,
                    answerType: answerTypes[0].answerTypeId
                }
            ]
        })
    }

    const calncelHandler = () => {
        navigate('./../')
    }

    const renderAnswerTypes = () => {
        return answerTypes.map(answerType =>
            <option
                key={answerType.answerTypeId}
                value={answerType.answerTypeId}
            >
                {answerType.answerTypeTitle}
            </option>
        )
    }

    const checkQuestions = () => {
        if(questionare.questions.length === 0) {
            return false
        }
        return questionare.questions.every(question => !!question.question)
    }

    const valiedateInputs = () =>
        !questionare.type.name
        || !questionare.type.startDate
        || !questionare.type.endDate
        || !checkQuestions()
        || !questionare.type.number


    const updateHandler = async () => {
        if(valiedateInputs()) {
            setMessage({message: 'Введіть усі поля для анкети', severity: SEVERITY_TYPE.ERROR})
            return
        }
        setTouched(false)
        setLoading(true)
        const id = getCurrentPathId()
        try {
            await updateQuestionare(id, {
                startDate: questionare.type.startDate,
                endDate: questionare.type.endDate,
                name: questionare.type.name,
                questions: questionare.questions,
                number: questionare.type.number,
                role: questionare.type.role
            })
            navigate('./..')
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(false)
        }
    }

    const addHandler = async () => {
        if(valiedateInputs()) {
            setMessage({message: 'Введіть усі поля для анкети', severity: SEVERITY_TYPE.ERROR})
            return
        }
        setLoading(true)
        try {
            await addQuestionare({
                startDate: questionare.type.startDate,
                endDate: questionare.type.endDate,
                name: questionare.type.name,
                questions: questionare.questions,
                number: questionare.type.number,
                role: questionare.type.role
            })
            navigate('./..')
        } catch (e) {
            setMessage({message: e.message, severity: SEVERITY_TYPE.ERROR})
        } finally {
            setLoading(false)
        }
    }

    const renderQuestions = () => {
        return questionare.questions.map((question, index) =>
            <li className="questions__question" key={question.questionId}>
                <span className="questions__number">{index + 1}</span>
                <div className="questions__content">
                    <textarea
                        type="text"
                        className='qestions__name'
                        placeholder='Введіть питання'
                        value={question.question}
                        onChange={(e) => changeQuestion(e.target.value, question.questionId)}
                    />
                    <select
                        className='qestions__type'
                        value={question.answerType}
                        onChange={e => changeAnswerType(e.target.value, question.questionId)}
                    >
                        {renderAnswerTypes()}
                    </select>
                    <button
                        className="questions__remover"
                        onClick={() => deleteQuestion(question.questionId)}
                    >
                        <img src={deletting} alt="" />
                    </button>
                </div>
            </li>
        )
    }

    const renderContent = () =>
        <section className='questionnaireCreator'>
            <div className="container">
                <div className="toggler-container">
                    <div className="toggler">
                        <button onClick={changeRole} className={questionare.type.role === 'doctor' ? 'active' : ''}>Лікар</button>
                        <button onClick={changeRole} className={questionare.type.role === 'psycho' ? 'active' : ''}>Психолог</button>
                    </div>
                </div>
                <div className="questionnaireCreator__data">
                    <textarea 
                        type="text"
                        className='questionnaireCreator__title'
                        placeholder='Введіть назву анкети'
                        value={questionare.type.name}
                        onChange={changeName}
                    />
                    <label className='questionnaireCreator__label number'>
                        Номер:
                        <input
                            type="text"
                            className='questionnaireCreator__date'
                            value={questionare.type.number}
                            placeholder='Введіть номер анкети'
                            onChange={changeNumber}
                        />
                    </label>
                </div>
                <div className="questionnaireCreator__data">
                    <label className='questionnaireCreator__label'>
                        Початок дії
                        <input
                            type="date"
                            className='questionnaireCreator__date'
                            value={questionare.type.startDate}
                            onChange={changeStartDate}
                        />
                    </label>
                    <label className='questionnaireCreator__label'>
                        Кінець дії
                        <input
                            type="date"
                            className='questionnaireCreator__date'
                            value={questionare.type.endDate}
                            onChange={changeEndDate}
                        />
                    </label>
                </div>
                <ul className="questions">
                    <h2 className="questions__title">Редагування питань</h2>
                    {renderQuestions()}
                </ul>
                <button
                    className="questionnaireCreator__adder button"
                    onClick={addQuestion}
                >
                    Додати питання
                </button>
                {touched ?
                    <div className="questionnaireCreator__controlls">
                        {editable ?
                            <button
                                className="questionnaireCreator__button button"
                                onClick={updateHandler}
                            >
                                Зберегти
                            </button>:
                            <button
                                className="questionnaireCreator__button button"
                                onClick={addHandler}
                            >
                                Cтворити
                            </button>
                        }
                        <button
                            className="questionnaireCreator__button button"
                            onClick={calncelHandler}
                        >
                            Скасувати
                        </button>
                    </div>:
                    null
                }
            </div>
        </section>

    return loading ?
        <>
            {renderContent()}
            <Loading />
        </>:
        renderContent();
}

export default QuestionnaireCreator;