import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { CODE_HINT, CODE_MASK, NEGATIVE_ID, PHONE_MASK } from '../../constants/dataConstants';
import { getAllRequestCodes, getResults } from '../../services/recordsService';
import './result.styles.css';
import deletting from '../../assets/images/delete.svg'
import info from '../../assets/images/info.svg'
import { EXISTED_SESSION_MESSAGE, SELECTED_FOR_PSYCHOLOGER_MESSAGE, SEVERITY_TYPE } from '../../constants/messages';
import { AlertContext } from '../../App';
import MaskedInput from 'react-text-mask';
import React from 'react';

function Result({
    questions,
    answers,
    onChange,
    value: inputValue,
    role, onAppeal,
    appeal,
    onCompleted, 
    completed,
    onCode,
    code,
    requestCode,
    onRequestCode
}) {

    const [resultsCases, setResultCases] = useState([])
    const [requestCodes, setRequestCodes] = useState([])
    const [openSelector, setOpenSelector] = useState(false)
    const [hintOpen, setHintOpen] = useState(false)
    const [, setMessage] = useContext(AlertContext)

    const hintHandler = () => {
        if(window.innerWidth > 1000) return;
        setHintOpen(!hintOpen)
    }

    const isSuitableForPsychologer = inputValue.some(id => id === 4 || id === 5)
    && inputValue.length > 0
    && role === 'doctor'

    useEffect(() => {
        if(appeal.readonly) {
            setMessage({message: EXISTED_SESSION_MESSAGE, severity: SEVERITY_TYPE.WARNING})
        }
    }, [appeal])

    useEffect(() => {
        if(role) {
            getResults(role)
                .then(response => setResultCases(response))
        }
        if(appeal.id) {
            setOpenSelector(true)
        }

    }, [role, appeal.asigner])

    useEffect(() => {
        getAllRequestCodes()
            .then(codes => {
                setRequestCodes(codes)
            })        
    }, [setRequestCodes])

    const getRequestCodeOptions = () => {
        const options = requestCodes.map(code => 
            <option 
                key={code.id}
                title={code.description}
                value={code.id}
            >
                {code.code}
            </option>
        )

        options.unshift(<option 
            key={-1}
            value={-1}
        >
            Не обрано
        </option>)

        return options
    }

    const changeQuestions = (questions) => {
        onAppeal({
            ...appeal,
            questions
        })
    }

    const changePhone = (e) => {
        onAppeal({
            ...appeal,
            phone: e.target.value
        })
    }

    const changeCompletedText = (e) => {
        onCompleted({
            ...completed,
            completedText: e.target.value
        })
    }

    const changeCompletedMark = () => {
        onCompleted({
            ...completed,
            isCompleted: !completed.isCompleted
        })
    }

    const addQuestion = () => {
        changeQuestions([...appeal.questions, {text: ''}])
    }

    const changeQuestion = (changedIndex, text) => {
        changeQuestions(appeal.questions.map((question, index) => {
            if(index === changedIndex) {
                return {
                    ...question,
                    text
                }
            }
            return question
        }))
    }

    const deleteQuestion = (id) => {
        changeQuestions(appeal.questions.filter((_, index) => index !== id))
    }

    const toggleOpened = (next) => {
        onAppeal({
            ...appeal,
            deleted: !next
        })
        
        setOpenSelector(next)
    }

    const changeCode = (event)=> {
        onCode(event.target.value)
    }

    const firstType = (answer, question) =>
        <li className="result__question" key={answer.id}>
            <p className="result__name">
                {question.question}
            </p>
            <p className="result__value">
                {answer.answerText}
            </p>
        </li>


    const changeHandler = (id) => {
        let allowToChange = true
        if(isSuitableForPsychologer && openSelector) {
            allowToChange = window.confirm(SELECTED_FOR_PSYCHOLOGER_MESSAGE)
        }

        if(!allowToChange) {
            return
        }

        toggleOpened(false)

        if (id === NEGATIVE_ID) {
            onChange([id])
        } else if (inputValue.includes(id)) {
            onChange(inputValue.filter(result => result !== id && result !== NEGATIVE_ID))
        } else {
            onChange([...inputValue.filter(result=> result !== NEGATIVE_ID), id])
        }
    }

    const seocndType = (answer, question) =>
        <li className="result__question" key={answer.id}>
            <p className="result__name">
                {question.question}
            </p>
            <p className="result__value">
                {answer.comment}
            </p>
        </li>

    const answerFactory = {
        1: firstType,
        2: seocndType
    }

    const renderResult = () => {
        return resultsCases.map(result => <div className="simple__field centred" key={result.id}>
            <label className="simple__check">
                <input type="checkbox"
                    hidden
                    disabled={appeal.readonly}
                    value={result.id}
                    onChange={changeHandler.bind(null, result.id)}
                    checked={inputValue.includes(result.id)}
                />
                <span className="simple__frame" />
            </label>
            <span className="label">{ result.name }</span>
        </div>)
    }

    const renderAnswers = () => {
        return answers.map(answer => {
            const question = questions.find(question => question.questionId === answer.questionId)
            return answerFactory[question.answerType](answer, question)
        })
    }

    const renderQuestions = () => {
        return appeal.questions.map((question, index) =>
            <li className="questions__question" key={index}>
                <span className="questions__number">{index + 1}</span>
                <div className="questions__content">
                    <textarea
                        disabled={appeal.readonly}
                        type="text"
                        className='qestions__name'
                        placeholder='Введіть питання'
                        value={question.text}
                        onChange={(e) => changeQuestion(index, e.target.value)}
                    />                   
                    <button
                        disabled={appeal.readonly}
                        className="questions__remover"
                        onClick={() => deleteQuestion(index)}
                    >
                        <img src={deletting} alt="" />
                    </button>
                </div>
            </li>
        )
    }

    return (
        <div className="result">
            <ul className="result__questions">
                {renderAnswers()}
            </ul>
            <div className="result__result">
                <p className="result__label">
                    Результат тесту:
                </p>
                <div className="result__container">
                    {renderResult()}
                </div>
            </div>
            <div className="userStatus">
                { isSuitableForPsychologer ?
                    <>
                        <div className='appeal__controls'>
                            {/*eslint-disable-next-line jsx-a11y/label-has-associated-control*/}
                            <label className="filler__label code__label">
                                <p className='code__hint'>
                                    Код Сase++
                                    <img
                                        onClick={hintHandler}
                                        src={info} 
                                        alt='' 
                                        title={CODE_HINT}>
                                    </img>
                                </p>
                                { hintOpen ? <p>{CODE_HINT}</p> : null }
                            
                                <MaskedInput
                                    mask={CODE_MASK}
                                    type="text"
                                    className='filler__textarea filler__input'
                                    value={code}
                                    onChange={changeCode}
                                />
                            </label>
                            <label className="filler__label code__label">
                                Код звернення:
                                <select
                                    className="filler__input"
                                    value={requestCode}
                                    onChange={(e) => onRequestCode(+e.target.value)}
                                >
                                    {getRequestCodeOptions()}
                                </select>
                            </label>
                        </div>
                        <div className='appeal__controls'>
                            <div className="simple__field">
                                <label className="simple__check">
                                    <input type="checkbox"
                                        hidden
                                        onChange={changeCompletedMark}
                                        checked={completed.isCompleted}
                                    />
                                    <span className="simple__frame" />
                                </label>
                                <span className="label">Доведений до лікування</span>
                            </div>
                            <label className="filler__label">
                                Доведено до лікування
                                <textarea
                                    type="text"
                                    className='filler__textarea filler__input'
                                    value={completed.completedText}
                                    onChange={changeCompletedText}
                                />
                            </label>
                        </div>
                        <div className='appeal__controls'>
                            <div className="simple__field">
                                <label className="simple__check">
                                    <input type="checkbox"
                                        hidden
                                        onChange={() => toggleOpened(!openSelector)}
                                        checked={openSelector}
                                        disabled={appeal.readonly}
                                    />
                                    <span className="simple__frame" />
                                </label>
                                <span className="label">Направити до психолога</span>
                            </div>
                            {/*eslint-disable-next-line jsx-a11y/label-has-associated-control*/}
                            <label className="filler__label">
                                Номер
                                <MaskedInput
                                    mask={PHONE_MASK}
                                    type="text"
                                    className="filler__input"
                                    value={appeal.phone}
                                    onChange={changePhone}
                                    disabled={appeal.readonly || !isSuitableForPsychologer}
                                />
                            </label>
                        </div>
                    </>
                    : null
                }
                {openSelector && isSuitableForPsychologer ?
                    <div className="psychologer__container">
                        <button
                            onClick={addQuestion}
                            disabled={appeal.readonly}
                            className="questionnaireResult__adder button"
                        >
                            Додати питання
                        </button>
                        {renderQuestions()}
                    </div>
                    : null
                }
            </div>
        </div>
    );
}

export default Result;